<template>
  <div class="h-100 w-100 position-relative">
    <div class="row">
      <!-- <div class="col-lg-3 col-12">
        <div class="card" style="border-radius: 0.5rem;">
          <div class="card-body py-2">
            <div class="d-flex align-items-center justify-content-between">
              <p class="mb-0 text-sm text-uppercase font-weight-bold text-start title-color">
                Number of Cases
              </p>
              <h5 class="mb-0 font-weight-bolder">
                {{ numberOfCases }}
              </h5>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-lg-8 col-12"> -->
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-end mb-3 gap-2">
          <argon-input id="cases_search" type="search" name="cases_search" class="mb-0 w-100"
            placeholder="Search cases..." icon-dir="left" icon="fas fa-search" :value="query"
            @input="onCaseSearch($event.target.value)" />
          <div>
            <button v-if="totalCases > 5000" class="btn btn-primary mb-0" @click="showExportError">
              Export
            </button>
            <download-excel v-else worksheet="Cases" type='xlsx' name="cases.xlsx" class="btn btn-primary mb-0"
              :class="totalCases == 0 ? 'btn-secondary pe-none' : ''" :fetch="exportData" :fields="json_fields"
              :debounce="3000">
              Export
            </download-excel>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-12">
        <mini-statistics-card title="Total Payment" :value="'£' + totalPayment" :icon="{
          component: 'fa fa-money',
          background: 'bg-primary',
          shape: 'rounded-circle',
        }" />
      </div>
      <div class="col-lg-4 col-12">
        <mini-statistics-card title="Average Payment" :value="'£' + avgPayment" :icon="{
          component: 'ni ni-money-coins',
          background: 'bg-primary',
          shape: 'rounded-circle',
        }" />
      </div> -->
    </div>
    <div class="row">
      <div class="mt-4 col-lg-3 col-12">
        <doughnut-chart id="Cases_by_Case_Type" height="200" title="Case Type"
          :colors="getColors(casesByCaseTypeData.labels)" :chart="{
            labels: casesByCaseTypeData.labels,
            datasets: {
              label: 'Case Type',
              data: casesByCaseTypeData.cases,
            },
          }" :show-total-in-center="true" :total="totalCases" :crop-label="true"
          :crop-label-length="isMobile() ? 40 : 22" />
      </div>
      <div class="mt-4 col-lg-3 col-12">
        <doughnut-chart id="Cases_by_Result" height="200" title="Cases by Result"
          :colors="getColors(casesByResultChartData.labels)" :chart="{
            labels: casesByResultChartData.labels,
            datasets: {
              label: 'Cases by Result',
              data: casesByResultChartData.cases,
            },
          }" :crop-label="true" :crop-label-length="isMobile() ? 40 : 22">
          <template #title-header>
            <img :src="require('@/assets/img/ai.png')" height="15px">
          </template>
        </doughnut-chart>
      </div>
      <!-- <div class="mt-4 col-lg-3 col-12">
        <doughnut-chart id="Cases_by_Jurisdiction_Code" height="200" title="Point of Law"
          :colors="getColors(casesByJurisdictionCodeData.labels)" :chart="{
              labels: casesByJurisdictionCodeData.labels,
              datasets: {
                label: 'Point of Law',
                data: casesByJurisdictionCodeData.cases,
              },
            }" :crop-label="true" :crop-label-length="isMobile() ? 40 : 22" />
      </div> -->
      <!-- <div class="mt-4 col-lg-3 col-12">
        <doughnut-chart id="Cases_by_Industry" height="200" title="Case Industry"
          :colors="getColors(casesByCaseIndustryData.labels)" :chart="{
              labels: casesByCaseIndustryData.labels,
              datasets: {
                label: 'Case Industry',
                data: casesByCaseIndustryData.cases,
              },
            }" :crop-label="true" :crop-label-length="isMobile() ? 40 : 22" />
      </div> -->
      <div class="mt-4 col-lg-6 col-12">
        <div class="card z-index-2">
          <div class="p-3 pb-0 card-header">
            <h6 class="title-color" style="font-size: 0.8rem;">Cases Timeline</h6>
          </div>
          <div class="p-3 card-body">
            <div class="chart" style="height: 235px; width: 100%;">
              <canvas id="Cases_Timeline"></canvas>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="mt-4 col-lg-6 col-12">
        <div class="card z-index-2">
          <div class="p-3 pb-0 card-header text-center">
            <h6 class="title-color" style="font-size: 0.8rem;">Cases by Cities</h6>
          </div>
          <div class="p-3 card-body">
            <div id="mapid" class="leaflet" style="height: 300px;"></div>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-12 col-12">
        <bar-chart-horizontal id="cases_by_top_10_companies" :name-crop-value="isMobile() ? 15 : 30"
          bar-cursor-type="pointer" title="Cases by Top 10 Companies" :chart="{
          labels: casesByTop10CompaniesChartData.labels,
          datasets: {
            label: 'Cases',
            data: casesByTop10CompaniesChartData.cases,
          },
        }" :on-bar-click="onCasesByTop10CompaniesChartDataClick" />
      </div> -->
      <div class="mt-4 col-12">
        <div class="card overflow-auto mb-0">
          <div class="card-body p-3 pt-0 mt-3 overflow-auto" style="max-height: 400px;">
            <table class="table table-sm table-striped table-hover" style="table-layout: fixed; word-wrap: break-word;">
              <thead>
                <th scope="col">Case Name</th>
                <th class="text-center" scope="col">Date</th>
                <th class="text-center" scope="col">Claimant</th>
                <th class="text-center" scope="col">Case Type</th>
                <th scope="col">Respondent</th>
                <th scope="col">Point of Law</th>
                <th scope="col">Decision Files</th>
              </thead>
              <tbody>
                <tr v-for="(cases, i) of caseTable" :key="`${i}.${cases.decisionID} `">
                  <td class="value text-start align-baseline">
                    <span v-if="isNewCase(cases)" class="badge badge-sm bg-primary text-break text-white me-1 mt-1">
                      New
                    </span>
                    <span class="text-capitalize" :class="[cases.decisionID ? 'case_title' : '']"
                      @click="activeCaseDetailsTab(String(cases.decisionID), cases?.caseName ?? '')">{{ cases.caseName
                        || '-'
                      }}</span>
                  </td>
                  <td class="value text-center text-capitalize align-baseline">
                    {{ !cases.caseDate ? '-' : `${getFormatedDate(cases.caseDate, '', '', true)}` }}
                  </td>
                  <td class="value text-center text-capitalize align-baseline">
                    {{ cases.claimant ? capitalizeFirstLetter(cases.claimant) : '-' }}
                  </td>
                  <td class="value text-center text-capitalize align-baseline">{{ cases.caseType || '-' }}</td>
                  <td class="value text-capitalize align-baseline">
                    <!-- <template v-if="cases?.respondent && cases?.respondent.length > 0">
                      <template v-for="(respondent, ind) of cases.respondent" :key="respondent">
                        <span
                          v-if="(cases?.respondantData || []).some(c => String(c?.respondent || '').toLowerCase() == String(respondent || '').toLowerCase())"
                          class="case_title"
                          @click.prevent="onRespondantClick((cases?.respondantData || []).find(c => String(c?.respondent || '').toLowerCase() == String(respondent || '').toLowerCase()))">
                          {{ `${capitalizeFirstLetter(respondent)}${ind == cases.respondent.length - 1 ? '' : ', '}` }}
                        </span>
                        <span v-else>
                          {{ `${capitalizeFirstLetter(respondent)}${ind == cases.respondent.length - 1 ? '' : ', '}` }}
                        </span>
                      </template>
  </template>
  <span v-else>-</span> -->
                    <span>
                      {{ cases?.respondent && cases?.respondent.length > 0 ? cases?.respondent.join(", ") : '-' }}
                    </span>
                  </td>
                  <td class="value align-baseline">
                    <div v-if="cases.category && cases.category.length"
                      class="d-flex align-items-center flex-wrap gap-2">
                      <span v-for="code of cases.category" :key="code"
                        class="badge badge-sm bg-secondary text-wrap text-break text-start lh-base cursor-pointer"
                        style="text-transform: none !important;" @click.prevent="onPointOfLawPress(code)">
                        {{ code }}
                      </span>
                    </div>
                    <span v-else class="text-center">-</span>
                    <!-- {{ cases.category ? cases.category.join(", ") : '-' }} -->
                  </td>
                  <td class="text-center align-baseline">
                    <div class="d-flex align-items-center justify-content-center flex-wrap gap-1">
                      <template v-if="cases.fileLinks && cases.fileLinks.length > 0">
                        <a v-for="file of cases.fileLinks" :key="file" :href="file" target="_blank">
                          <img :src="require('@/assets/img/pdf-icon.svg')" alt="file icon" class="me-2"
                            style="height: 25px;" />
                        </a>
                      </template>
                      <span v-else class="text-center">-</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="caseTable.length == 0">
              <h4 class="text-center text-lg">No cases found</h4>
            </div>
          </div>
          <div v-if="totalCases > 0" class="card-footer px-0">
            <div class="pagination-container">
              <vue-awesome-paginate v-model="pageNo" :max-pages-shown="3" :items-per-page="dataSize"
                :total-items="totalCases" :disable-pagination="fetching" @click="onPageNoChange">
                <template #prev-button>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="black" width="10" height="10" viewBox="0 0 24 24">
                    <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                  </svg>
                </template>
                <template #next-button>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="black" width="10" height="10" viewBox="0 0 24 24">
                      <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                    </svg>
                  </span>
                </template>
              </vue-awesome-paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="fetching" class="empty-view">
      <argon-loader />
    </div>
  </div>
</template>

<script>
// import L from "leaflet";
import moment from "moment";
import omit from "lodash-es/omit";
import Chart from "chart.js/auto";
import JsonExcel from "vue-json-excel3";
import { mapGetters, mapMutations } from "vuex";

// import BarChartHorizontal from "@/views/pages/components/BarChartHorizontal.vue";
// import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import DoughnutChart from "@/views/pages/components/DoughnutChart.vue";
import ArgonLoader from '@/components/ArgonLoader.vue';
import ArgonInput from "@/components/ArgonInput.vue";

import {
  colors,
  isMobile,
  getColors,
  isNewCase,
  handleError,
  clearEmpties,
  // nFormatter,
  // isDateBetween,
  // generateRandom,
  formatedNumber,
  getFormatedDate,
  capitalizeFirstLetter,
} from "@/utils/utils.js";
import elasticSearchApiInstance, {
  // dataSize as eDataSize,
  casesTabChartFilterAggs,
} from "@/utils/elasticSearch";
import eventBus from "@/utils/eventBus";

export default {
  name: "CasesComponent",
  components: {
    ArgonInput,
    ArgonLoader,
    DoughnutChart,
    // BarChartHorizontal,
    // MiniStatisticsCard,
    downloadExcel: JsonExcel
  },
  data() {
    return {
      fetching: false,
      // mapZoom: {
      //   start: 0,
      //   end: 0,
      //   initial: 0,
      // },
      chart_id: "Cases_Timeline",
      // map: null,
      // tiles: [],
      // avgPayment: 0,
      // totalPayment: 0,
      query: '',
      // numberOfCases: 0,
      caseTable: [],
      pageNo: 1,
      dataSize: 50,
      totalCases: 0,
      casesByResultChartData: {
        labels: [],
        cases: [],
      },
      // casesByJurisdictionCodeData: {
      //   labels: [],
      //   cases: [],
      // },
      casesByCaseTypeData: {
        labels: [],
        cases: [],
      },
      // casesByCaseIndustryData: {
      //   labels: [],
      //   cases: [],
      // },
      casesTimelineChartData: {
        labels: [],
        cases: [],
      },
      // casesByTop10CompaniesChartData: {
      //   labels: [],
      //   cases: [],
      // },
      json_fields: {
        "Case Name": "case_name",
        Date: "date",
        Claimant: "claimant",
        "Case Type": "case_type",
        Respondent: "respondent",
        "Point of Law": "decision",
        "Decision Files": "decision_files",
      },
    }
  },
  computed: {
    ...mapGetters("dashboard", [
      "casesFilters", "caseDetailFilters"
      // "cities", "companies",
    ]),
  },
  watch: {
    casesFilters: {
      handler: function () {
        if (this.pageNo != 1) {
          this.pageNo = 1;
        }
        this.loadData();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.pageNo != 1) {
      this.pageNo = 1;
    }
    this.loadData();
  },
  created() {
    eventBus.on('on-reset-filter', () => {
      this.query = '';
    });
  },
  methods: {
    isMobile,
    getColors,
    isNewCase,
    getFormatedDate,
    capitalizeFirstLetter,
    ...mapMutations("dashboard", ["updateCompanyFilters", "updateCaseDetailFilters", "updateCasesFilters"]),
    onPageNoChange(page) {
      this.pageNo = page;
      setTimeout(async () => {
        this.fetching = true;
        try {
          await this.searchCases();
          this.fetching = false;
        } catch (error) {
          this.fetching = false;
        }
      }, 300);
    },
    showExportError() {
      this.$swal({
        title: 'Error!',
        text: 'Too many items to export, Please filter to narrow down.',
        icon: 'error',
        confirmButtonText: 'Okay',
        customClass: {
          confirmButton: "btn bg-success",
        },
        buttonsStyling: false,
      });
    },
    async loadData() {
      try {
        this.fetching = true;
        await this.searchCases();
        await this.calculateData();
        this.fetching = false;
      } catch (error) {
        this.fetching = false;
      }
    },
    getQueryFilters() {
      const filter = [], should = [];
      if (this.casesFilters.selectedCasesDate.length > 0) {
        const [startDate, endDate] = this.casesFilters.selectedCasesDate;
        let sDate = '', eDate = '';
        const dateFormat = 'YYYY-MM-DD';
        if (startDate) sDate = moment(startDate).format(dateFormat);
        if (endDate) eDate = moment(endDate).format(dateFormat);
        if (sDate || eDate) {
          const range = { caseDate: { boost: 2.0 } };
          if (sDate) range.caseDate['gte'] = sDate;
          if (sDate && eDate) range.caseDate['lte'] = eDate;
          if (sDate && !eDate) {
            const newSDate = moment(startDate);
            newSDate.add(1, 'day');
            range.caseDate['lte'] = newSDate.format(dateFormat);
          };

          filter.push({ range });
        }
      }
      if (this.casesFilters.selectedIndustry) {
        filter.push({ term: { 'industries.GroupShort.keyword': this.casesFilters.selectedIndustry } });
      }
      if (this.casesFilters.selectedSubIndustry) {
        filter.push({ term: { 'industries.CodeDesc.keyword': this.casesFilters.selectedSubIndustry } });
      }
      if (this.casesFilters.selectedCompany) {
        filter.push({ term: { 'companies.id.keyword': this.casesFilters.selectedCompany } });
      }
      if (this.casesFilters.selectedCity) {
        filter.push({ term: { 'city.keyword': this.casesFilters.selectedCity } });
      }
      if (this.casesFilters.selectedResult) {
        filter.push({ term: { 'result.keyword': this.casesFilters.selectedResult } });
      }
      if (this.casesFilters.selectedPointOfLaw) {
        filter.push({ term: { 'category.keyword': this.casesFilters.selectedPointOfLaw } });
      }
      if (this.casesFilters.selectedJudge) {
        filter.push({ term: { 'heardBefore.keyword': this.casesFilters.selectedJudge } });
      }
      if (this.casesFilters.selectedCaseType) {
        filter.push({ term: { 'caseType.keyword': this.casesFilters.selectedCaseType } });
      }
      if (this.query) {
        should.push({ match_phrase_prefix: { caseName: { query: this.query, boost: 2 } } });
        should.push({ match: { caseName: { query: this.query, boost: 1 } } });
      }
      return { filter, should };
    },
    async calculateData() {
      try {
        const filterData = {
          size: 0,
          query: { bool: {} },
          aggs: casesTabChartFilterAggs,
        };
        const sideFilters = this.getQueryFilters();
        if (this.query) {
          filterData.min_score = 1;
          filterData.query.bool.minimum_should_match = 1;
        }
        if (sideFilters?.filter?.length || sideFilters?.should?.length) {
          filterData.query.bool = sideFilters;
        }

        const response = await elasticSearchApiInstance.post('/_search', clearEmpties(filterData));
        const { casesByTimeline, caseByTypes, caseByResults } = response?.data?.aggregations ?? {};

        // const totalPayment = allCases.flatMap(cd => (cd?.orderedPayment || [])).reduce((a, b) => Number(a) + (isNaN(b) ? 0 : Number(b)), 0);
        // this.totalPayment = nFormatter(totalPayment, 1);
        // this.numberOfCases = allCases.length || 0;
        // this.avgPayment = nFormatter(totalPayment / this.numberOfCases, 1);

        this.casesByResultChartData = {
          labels: (caseByResults?.buckets ?? []).map(c => capitalizeFirstLetter(c?.key ?? '')),
          cases: (caseByResults?.buckets ?? []).map(c => c.doc_count),
        };

        // const jurCodes = _.uniq(allCases.flatMap(c => c.category || []).filter(j => j));
        // const casesByJurisdictionCode = _.orderBy(jurCodes.map(jc => ({
        //   jurisdictionCode: jc,
        //   cases: allCases.filter(d => (d.category || []).includes(jc)),
        //   casesLength: allCases.filter(d => (d.category || []).includes(jc)).length
        // })), 'casesLength', 'desc');
        // this.casesByJurisdictionCodeData = {
        //   labels: casesByJurisdictionCode.map(jc => capitalizeFirstLetter(jc.jurisdictionCode)),
        //   cases: casesByJurisdictionCode.map(jc => jc.casesLength),
        // };

        this.casesByCaseTypeData = {
          labels: (caseByTypes?.buckets ?? []).map(vt => capitalizeFirstLetter(vt?.key ?? '')),
          cases: (caseByTypes?.buckets ?? []).map(vt => vt.doc_count),
        };

        // const casesByIndustry = {};
        // allCases.filter(c => c?.industryData && c?.industryData.length > 0).forEach(cs => {
        //   cs.industryData.forEach(ind => {
        //     if (ind?.GroupShort) {
        //       if (casesByIndustry[ind?.GroupShort]) {
        //         casesByIndustry[ind?.GroupShort].push(cs);
        //       } else {
        //         casesByIndustry[ind.GroupShort] = [cs];
        //       }
        //     }
        //   })
        // });
        // const casesByIndustryData = _.orderBy(Object.entries(casesByIndustry).map(([key, value]) => ({
        //   label: key,
        //   casesLength: value.length,
        // })), 'casesLength', ['desc']);
        // this.casesByCaseIndustryData = {
        //   labels: casesByIndustryData.map((c) => c.label),
        //   cases: casesByIndustryData.map((c) => c.casesLength),
        // }
        const timelineCases = (casesByTimeline?.buckets ?? []).filter(c => c?.doc_count > 0);
        this.casesTimelineChartData = {
          labels: timelineCases.map(c => new Date(c.key).getFullYear()),
          cases: timelineCases.map(c => c.doc_count),
        };

        // const top10CompanyDataObject = {};
        // const allCompanyResults = _.uniq(allCases.flatMap(c => (c?.companyRef ?? []).filter(cID => cID)));
        // allCompanyResults.forEach(result => {
        //   top10CompanyDataObject[result] = {
        //     companyData: this.companies.find(c => c.id == result),
        //     cases: allCases.filter(c => (c?.companyRef ?? []).includes(result))
        //   };
        // });
        //
        // const casesByTop10Companies = _.chain(Object.entries(top10CompanyDataObject)).map(([key, value]) => ({
        //   caseCompany: key,
        //   cases: value,
        // })).sort((a, b) => (b?.cases?.cases || []).length - (a?.cases?.cases || []).length).slice(0, 10).value();
        // 
        // this.casesByTop10CompaniesChartData = {
        //   labels: casesByTop10Companies.map(c => capitalizeFirstLetter(c?.cases?.companyData?.name || '')),
        //   cases: casesByTop10Companies.map(c => (c?.cases?.cases || []).length),
        // };

        // let casesByCities2 = [];
        // const allCityResults = _.uniq(allCases.flatMap(c => (c?.cityData ?? []))).map(c => c.City);
        // allCityResults.forEach(result => {
        //   const cityData = this.cities.find(c => result == c.City) || {};
        //   const cityDataLat = (cityData.Lat || 0);
        //   const cityDataLng = (cityData.Lon || 0);
        //   if (cityDataLat !== 0 && cityDataLng !== 0) {
        //     const lat = cityDataLat + (0.0009 * generateRandom(1, 10));
        //     const lng = cityDataLng + (0.0009 * generateRandom(1, 10));
        //     casesByCities2.push({
        //       city: cityData.City,
        //       latlng: [lat, lng],
        //       totalCases: allCases.filter(c => (c?.city ?? []).includes(result)).length,
        //     });
        //   }
        // });

        // if (this.map) {
        //   this.map.remove();
        //   this.map = null;
        // }
        // setTimeout(() => {
        //   const initialMapData = {
        //     zoom: 7,
        //     maxBounds: 14,
        //     center: [51.5, -0.09],
        //   };
        //   if (this.casesFilters.selectedCity) {
        //     const cityData = this.cities.find(c => String(c.City).toLowerCase() == String(this.casesFilters.selectedCity).toLowerCase());
        //     if (cityData && !isNaN(cityData.Lat) && !isNaN(cityData.Lon)) {
        //       initialMapData.center = [cityData.Lat, cityData.Lon];
        //     }
        //   }
        //   this.map = L.map("mapid", initialMapData).setView(initialMapData.center, initialMapData.zoom);
        //   this.tiles = new L.tileLayer(
        //     "https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png",
        //     {
        //       attribution: '',
        //       subdomains: "abcd",
        //       maxZoom: "14",
        //     }
        //   ).addTo(this.map);
        //   if (!this.casesFilters.selectedCity) {
        //     const myPoints = casesByCities2.map((e) => L.marker(e.latlng));
        //     if (myPoints.length) {
        //       const featureGroup = new L.featureGroup(myPoints);
        //       this.map.fitBounds(featureGroup.getBounds());
        //     }
        //   }
        //   const myMarkers = L.layerGroup();
        //   this.mapZoom = {
        //     start: (this.map.getZoom() || 1),
        //     end: (this.map.getZoom() || 1),
        //     initial: (this.map.getZoom() || 1),
        //   };
        //   if (casesByCities2 && casesByCities2.length > 0) {
        //     casesByCities2.forEach(e => {
        //       const { radius1, radius2 } = this.getRadiusBasedOnCases(e.totalCases || 0);
        //       const marker = new L.circleMarker(e.latlng, {
        //         radius: radius2,
        //         color: colors[0],
        //         fillOpacity: 0.5,
        //         fillColor: colors[0],
        //         initialRadius: radius1,
        //       })
        //         .bindTooltip(JSON.stringify(e.totalCases), { permanent: true, className: "bg-transparent border-0 shadow-none font-weight-bold text-white text-monospace", direction: 'center', offset: [0, 0] })
        //         .addTo(this.map);
        //       marker.addTo(myMarkers);
        //     });

        //     setTimeout(() => {
        //       myMarkers.addTo(this.map);
        //       this.map.on('zoomstart', () => {
        //         this.mapZoom.start = (this.map.getZoom() || 1);
        //       });
        //       this.map.on('zoomend', () => {
        //         this.mapZoom.end = (this.map.getZoom() || 1);
        //         const diff = this.mapZoom.start - this.mapZoom.end;
        //         myMarkers.eachLayer((marker) => {
        //           const calc = (marker.options.initialRadius * this.mapZoom.end) / this.mapZoom.initial;
        //           if (this.mapZoom.end == this.mapZoom.initial) {
        //             marker.setRadius(marker.options.initialRadius);
        //           } else if (diff > 0) {
        //             marker.setRadius(calc / 1.25);
        //           } else if (diff < 0) {
        //             marker.setRadius(calc * 1.25);
        //           }
        //         });
        //       });
        //       if (this.casesFilters.selectedCity) {
        //         this.map.setZoom(10);
        //       }
        //     }, 500);
        //   }
        // }, 1000);
        this.loadCasesTimeline();
      } catch (error) {
        const errorMessage = handleError(error);
        if (errorMessage) {
          this.$swal({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: "btn bg-success",
            },
            buttonsStyling: false,
          });
        }
      }
    },
    async searchCases() {
      try {
        const from = this.pageNo - 1 != 0 ? (this.dataSize) * (this.pageNo - 1) : this.pageNo - 1;
        const filterData = {
          from: from,
          size: this.dataSize,
          query: { bool: {} },
          sort: ['_score', { caseDate: { order: 'desc' } }]
        };
        const sideFilters = this.getQueryFilters();
        if (this.query) {
          filterData.min_score = 1;
          filterData.query.bool.minimum_should_match = 1;
        }
        if (sideFilters?.filter?.length || sideFilters?.should?.length) {
          filterData.query.bool = sideFilters;
        }
        const countFilterData = omit(filterData, ['from', 'size', 'sort', 'min_score']);
        const [response, countResponse] = await Promise.all([
          elasticSearchApiInstance.post('/_search', clearEmpties(filterData)),
          elasticSearchApiInstance.post('/_count', clearEmpties(countFilterData)),
        ]);
        const { hits: allCases = [] } = response?.data?.hits ?? {};
        this.totalCases = countResponse?.data?.count ?? 0;
        this.caseTable = allCases.map(c => c._source);
        if (!this.caseDetailFilters.selectedCase && this.caseTable.length > 0) {
          const firstCase = this.caseTable.find(c => c?.decisionID ?? '') || null;
          if (firstCase) {
            this.updateCaseDetailFilters({ selectedCase: String(firstCase?.decisionID ?? '') });
            eventBus.emit('on-case-details-change', {
              id: String(firstCase?.decisionID ?? ''),
              query: firstCase?.caseName ?? '',
            });
          }
        }
      } catch (error) {
        const errorMessage = handleError(error);
        if (errorMessage) {
          this.$swal({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: "btn bg-success",
            },
            buttonsStyling: false,
          });
        }
      }
    },
    async exportData() {
      try {
        const filterData = {
          size: this.totalCases,
          query: { bool: {} },
          sort: ['_score', { caseDate: { order: 'desc' } }]
        };
        const sideFilters = this.getQueryFilters();
        if (this.query) {
          filterData.min_score = 1;
          filterData.query.bool.minimum_should_match = 1;
        }
        if (sideFilters?.filter?.length || sideFilters?.should?.length) {
          filterData.query.bool = sideFilters;
        }

        const response = await elasticSearchApiInstance.post('/_search', clearEmpties(filterData));
        const { hits: allCases = [] } = response?.data?.hits ?? {};
        const caseData = allCases.map(c => c._source);

        const cData = caseData.map((value) => ({
          case_name: capitalizeFirstLetter(value.caseName),
          date: getFormatedDate(value.caseDate),
          claimant: capitalizeFirstLetter(value.claimant),
          case_type: capitalizeFirstLetter(value.caseType),
          respondent: (value?.respondent || []).map(r => capitalizeFirstLetter(r)).join(', '),
          decision: (value.category || []).map(c => capitalizeFirstLetter(c)).join(",\n"),
          decision_files: (value.fileLinks || []).join(',\n'),
        }));
        return cData;
      } catch (error) {
        const errorMessage = handleError(error);
        if (errorMessage) {
          this.$swal({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: "btn bg-success",
            },
            buttonsStyling: false,
          });
        }
        return [];
      }
    },
    // getRadiusBasedOnCases(casesLength) {
    //   const minSize = 7, maxSize = 150;
    //   const zoom = this.map.getZoom() || 1;
    //   const calc1 = (casesLength * 3.5) / zoom;
    //   const calc2 = (casesLength * 1.5) / zoom;
    //   const radius1 = calc1 > minSize ? calc1 < maxSize ? calc1 : maxSize : minSize;
    //   const radius2 = calc2 > minSize ? calc2 < maxSize ? calc2 : maxSize : minSize;
    //   return { radius1, radius2 }
    // },
    loadCasesTimeline() {
      var ctx = document.getElementById(this.chart_id).getContext("2d");

      var gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);

      // gradientStroke1.addColorStop(1, "rgba(33,82,255,0.1)");

      let chartStatus = Chart.getChart(this.chart_id);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      new Chart(ctx, {
        type: "line",
        data: {
          labels: this.casesTimelineChartData.labels,
          datasets: [
            {
              label: "Cases Timeline",
              tension: 0.3,
              pointRadius: 2,
              pointBackgroundColor: colors[0],
              borderColor: colors[0],
              borderWidth: 2,
              backgroundColor: gradientStroke1,
              data: this.casesTimelineChartData.cases,
              maxBarThickness: 6,
              fill: true,
              borderDash: [5],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              // beginAtZero: true, // commented for protype data
              beginAtZero: false,  // set false for protype data
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                stepSize: 500,
                display: true,
                padding: 10,
                color: "#9ca2b7",
                callback: function (label) {
                  return formatedNumber(label);
                }
              },
              border: {
                display: false
              }
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
              border: {
                dash: [5, 5],
                display: false
              }
            },
          },
        },
      });
    },
    // activeCompanyTab() {
    //   const get = document.getElementsByClassName("company-tab")[0];
    //   if (get) {
    //     setTimeout(() => {
    //       get.click();
    //       get.classList.add('active');
    //     }, 200);
    //   }
    // },
    // onCasesByTop10CompaniesChartDataClick(index) {
    //   const companyName = this.casesByTop10CompaniesChartData.labels[index];
    //   const companyData = this.companies.find(c => c.name == companyName);
    //   if (companyName && companyData?.id) {
    //     this.updateCompanyFilters({ selectedCompany: companyData.id || "" });
    //     this.activeCompanyTab();
    //   }
    // },
    activeCaseDetailsTab(caseId, caseName) {
      if (!caseId) return null;
      this.updateCaseDetailFilters({ selectedCase: caseId });
      eventBus.emit('on-case-details-change', { id: caseId, query: caseName });
      setTimeout(() => {
        const get = document.getElementsByClassName("case-details-tab")[0];
        if (get) {
          setTimeout(() => {
            get.click();
            get.classList.add('active');
          }, 200);
        }
      }, 500);
    },
    // onRespondantClick(respondent) {
    //   if (_.isEmpty(respondent)) return null;
    //   this.updateCompanyFilters({ selectedCompany: respondent.id || "" });
    //   this.activeCompanyTab();
    // },
    onCaseSearch(value) {
      this.query = value;
      if (this.pageNo != 1) {
        this.pageNo = 1;
      }
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        eventBus.emit('on-search-cases', value);
        this.loadData();
      }, 750);
    },
    onPointOfLawPress(code) {
      this.pageNo = 1;
      this.updateCasesFilters({ ...this.casesFilters, selectedPointOfLaw: code });
    }
  },
}
</script>
<style scoped>
canvas {
  height: 100% !important;
  width: 100% !important;
}

th {
  font-size: 0.9rem;
  text-wrap: wrap;
  text-align: center;
  padding: 0.75rem 0.25rem !important;
}

td.value {
  font-size: 0.8rem;
  line-height: 1.2rem;
  vertical-align: middle;
  text-align: center;
  text-wrap: wrap;
}

#Cases_Timeline {
  height: 100% !important;
  width: 100% !important;
}

@media only screen and (max-width : 768px) {
  table {
    table-layout: auto !important;
  }

  .th {
    padding: 0.75rem 1.25rem !important;
  }
}
</style>