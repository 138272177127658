<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-100 sidenav-collapse-main">
    <ul class="navbar-nav sidenav-list pb-4 h-100 overflow-hidden">
      <li class="nav-item" :class="{ 'd-none': activeTab !== 'cases' }">
        <sidenav-collapse :always-expanded="true" collapse-ref="cases_dates" :expanded="true" nav-text="Date">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12 date-range-picker-container">
                  <VueDatePicker range multi-calendars :ui="{ input: 'custome_calendar_picker_input' }"
                    :hide-navigation="['time']" :teleport="!isMobile()" :teleport-center="isMobile()"
                    :format="casesDatePickerformat" :model-value="casesFilters.selectedCasesDate"
                    @update:model-value="onCasesDateChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="{ 'd-none': activeTab !== 'cases' }">
        <sidenav-collapse :always-expanded="true" collapse-ref="filter-industries" :expanded="true"
          nav-text="Industries">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="filter-industries" class="form-select choices__inner border-0" name="filter-industries"
                    @change="onIndustryChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="{ 'd-none': activeTab !== 'cases' }">
        <sidenav-collapse :always-expanded="true" collapse-ref="filter-sub-industries" :expanded="true"
          class-name="choices__inner border-0" nav-text="Sub-Industries">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="filter-sub-industries" class="form-select choices__inner border-0"
                    name="filter-sub-industries" @change="onSubIndustryChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="{ 'd-none': activeTab !== 'cases' }">
        <sidenav-collapse :always-expanded="true" collapse-ref="company" :expanded="true"
          class-name="choices__inner border-0" nav-text="Company">
          <template #list>
            <ul class="nav ms-4 position-relative">
              <div class="row w-100">
                <div class="col-12">
                  <input id="cases_company_search_input" v-model="searchCompanyQuery"
                    class="form-input choices__inner border-0" placeholder="Search Company"
                    name="cases_company_search_input" autocomplete="off" @focus="onSearchInputFocus"
                    @blur="onSearchInputBlur" @input="handleCompanySearch($event.target.value)" />
                </div>
              </div>
              <div class="row w-100 search-suggestion-box-container"
                :class="{ show: showSearchSuggestions, hide: !showSearchSuggestions }">
                <div class="col-12 h-100">
                  <div class="search-suggestion-list">
                    <div v-for="item of casesTabFilters.companies" :key="item.id" class="item"
                      @click="onCompanyChange(item)" v-html="item?.highlightedTxt ?? ''" />
                    <div v-if="casesTabFilters.companies.length == 0" class="empty_message">
                      Start typing to search companies
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="{ 'd-none': activeTab !== 'cases' }">
        <sidenav-collapse :always-expanded="true" collapse-ref="filter-cities" :expanded="true"
          class-name="choices__inner border-0" nav-text="Cities">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="filter-cities" class="form-select choices__inner border-0" name="filter-cities"
                    @change="onCityChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="{ 'd-none': activeTab !== 'cases' }">
        <sidenav-collapse :always-expanded="true" collapse-ref="filter-result" :expanded="true"
          class-name="choices__inner border-0" nav-text="Result">
          <template #nav-header>
            <img :src="require('@/assets/img/ai.png')" height="13px" class="ms-2" />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="filter-result" class="form-select choices__inner border-0" name="filter-result"
                    @change="onResultChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="{ 'd-none': activeTab !== 'filter-cases' }">
        <sidenav-collapse :always-expanded="true" collapse-ref="point_of_law" :expanded="true"
          class-name="choices__inner border-0" nav-text="Point of Law">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="filter-point_of_law" class="form-select choices__inner border-0"
                    name="filter-point_of_law" @change="onPointOfLawChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="{ 'd-none': activeTab !== 'cases' }">
        <sidenav-collapse :always-expanded="true" collapse-ref="filter-judge" :expanded="true"
          class-name="choices__inner border-0" nav-text="Judge">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="filter-judge" class="form-select choices__inner border-0" name="filter-judge"
                    @change="onJudgeChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="{ 'd-none': activeTab !== 'cases' }">
        <sidenav-collapse :always-expanded="true" collapse-ref="filter-case_type" :expanded="true"
          class-name="choices__inner border-0" nav-text="Case Type">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="filter-case_type" class="form-select choices__inner border-0" name="filter-case_type"
                    @change="onCaseTypeChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <!-- <li class="nav-item" :class="{ 'd-none': activeTab !== 'case_details' }">
        <sidenav-collapse :always-expanded="true" collapse-ref="case_details_case" :expanded="true"
          class-name="choices__inner border-0" nav-text="Cases">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="case_details_case" class="form-select choices__inner border-0" name="case_details_case"
                    @change="onCaseDetailsCaseChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li> -->
      <li class="nav-item" :class="{ 'd-none': activeTab !== 'case_details' }">
        <sidenav-collapse :always-expanded="true" collapse-ref="case_details_case" :expanded="true"
          class-name="choices__inner border-0" nav-text="Cases">
          <template #list>
            <ul class="nav ms-4 position-relative">
              <div class="row w-100">
                <div class="col-12">
                  <input id="case_details_case_input" v-model="searchCaseQuery"
                    class="form-input choices__inner border-0" placeholder="Search Cases" name="case_details_case_input"
                    autocomplete="off" @focus="onSearchInputFocus" @blur="onSearchInputBlur"
                    @input="handleCaseSearch($event.target.value)" />
                </div>
              </div>
              <div class="row w-100 search-suggestion-box-container"
                :class="{ show: showSearchSuggestions, hide: !showSearchSuggestions }">
                <div class="col-12 h-100">
                  <div class="search-suggestion-list">
                    <div v-for="item of caseDetailsFilters" :key="item.decisionID" class="item"
                      @click="onCaseDetailsCaseChange(item)" v-html="item.highlightedTxt" />
                    <div v-if="caseDetailsFilters.length == 0" class="empty_message">
                      Start typing to search cases
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <!-- <li class="nav-item" :class="{ 'd-none': activeTab !== 'company' }">
        <sidenav-collapse :always-expanded="true" collapse-ref="company_company" :expanded="true"
          class-name="choices__inner border-0" nav-text="Company">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="company_company" class="form-select choices__inner border-0" name="company_company"
                    @change="onCompanyCompanyChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'industry'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="industry_years" :expanded="true"
          class-name="choices__inner border-0" nav-text="Years">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="industry_years" class="form-select choices__inner border-0" name="industry_years"
                    @change="onIndustryYearChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'industry'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="industry_industries" :expanded="true"
          class-name="choices__inner border-0" nav-text="Industries">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="industry_industries" class="form-select choices__inner border-0"
                    name="industry_industries" @change="onIndustryIndustriesChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'industry'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="industry_subIndustries" :expanded="true"
          class-name="choices__inner border-0" nav-text="Sub-Industries">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="industry_sub-industries" class="form-select choices__inner border-0"
                    name="industry_sub-industries" @change="onIndustrySubIndustryChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'industry'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="industry_case_type" :expanded="true"
          class-name="choices__inner border-0" nav-text="Case Type">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="industry_case_type" class="form-select choices__inner border-0" name="industry_case_type"
                    @change="onIndustryCaseTypeChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'legislation'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="tribunal_type" :expanded="true"
          class-name="choices__inner border-0" nav-text="Tribunal Type">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="tribunal_type" class="form-select choices__inner border-0" name="tribunal_type"
                    @change="onLNRTribunalTypeChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse :always-expanded="true" collapse-ref="law_type" :expanded="true"
          class-name="choices__inner border-0" nav-text="Law Type">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="law_type" class="form-select choices__inner border-0" name="law_type"
                    @change="onLNRLawTypeChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'trials'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="trials_date" :expanded="true" nav-text="Date">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12 date-range-picker-container">
                  <VueDatePicker range multi-calendars :ui="{ input: 'custome_calendar_picker_input' }" :hide-navigation="['time']" :teleport="!isMobile()"
                    :teleport-center="isMobile()" :format="trialDatePickerformat" :model-value="trialFilters.selectedTrialDate"
                    @update:model-value="onTrialsDateChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'trials'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="trials_type" :expanded="true"
          class-name="choices__inner border-0" nav-text="Type">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="trials_type" class="form-select choices__inner border-0" name="trials_type"
                    @change="onTrialsTypeChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'trials'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="trials_hearing_time" :expanded="true"
          class-name="choices__inner border-0" nav-text="Hearing Time">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="trials_hearing_time" class="form-select choices__inner border-0"
                    name="trials_hearing_time" @change="onTrialsHearingTimeChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'trials'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="trials_venue" :expanded="true"
          class-name="choices__inner border-0" nav-text="Venue">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="trials_venue" class="form-select choices__inner border-0" name="trials_venue"
                    @change="onTrialsVenueChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'trials'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="trials_caseno" :expanded="true"
          class-name="choices__inner border-0" nav-text="Case No.">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="trials_caseno" class="form-select choices__inner border-0" name="trials_caseno"
                    @change="onTrialsCaseNoChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'trials'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="trials_point_of_law" :expanded="true"
          class-name="choices__inner border-0" nav-text="Point of Law">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="trials_point_of_law" class="form-select choices__inner border-0"
                    name="trials_point_of_law" @change="onTrialsPointOfLawChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'trials'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="trials_claimant" :expanded="true"
          class-name="choices__inner border-0" nav-text="Claimant">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="trials_claimant" class="form-select choices__inner border-0" name="trials_claimant"
                    @change="onTrialsClaimantChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li> -->
      <li class="nav-item d-flex justify-content-center" :class="{ 'd-none': hideResetButton }">
        <argon-button color="primary" class="mt-3 py-2" @click.prevent="resetTabFilters">
          {{ isResetting ? 'Resetting...' : 'Reset Filters' }}
        </argon-button>
      </li>
    </ul>
  </div>
</template>

<script>
import { toRaw } from 'vue';
import moment from "moment";
import Choices from "choices.js";
import sortBy from "lodash-es/sortBy";
import uniqBy from "lodash-es/uniqBy";
import { mapGetters, mapMutations } from 'vuex';
import VueDatePicker from '@vuepic/vue-datepicker';

import ArgonButton from "@/components/ArgonButton.vue";
import SidenavCollapse from "./SidenavCollapse.vue";

import elasticSearchApiInstance, {
  casesTabFilterAggs,
  getOnlyIndustries,
} from '@/utils/elasticSearch';
import { isMobile, clearEmpties, handleError } from '@/utils/utils.js';
import eventBus from '@/utils/eventBus';

export default {
  name: "SidenavList",
  components: {
    ArgonButton,
    VueDatePicker,
    SidenavCollapse,
  },
  data() {
    return {
      searchCompanyQuery: '',
      searchCaseQuery: '',
      searchQuery: '',
      showSearchSuggestions: false,
      showSidebarContent: true,
      isResetting: false,
      industry_selectedSubIndustry: '',
      casesTabFilters: {
        industries: [],
        subIndustries: [],
        companies: [],
        caseTypes: [],
        cities: [],
        judges: [],
        pointOfLaws: [],
        results: [],
      },
      caseDetailsFilters: [],
    }
  },
  computed: {
    ...mapGetters("dashboard", [
      "casesFilters", "activeTab",
      // "lawAndRegs", "trialsData", "trialFilters", "lnrFilters", "industryFilters", "companyFilters", "cities", 'industries', 'companies', "cases",
    ]),
    isAuthenticated: function () {
      return toRaw(this?.$auth0?.isAuthenticated)?.value || process.env.NODE_ENV == 'development';
    },
    hideResetButton: function () {
      return this.activeTab === 'case_details' || this.activeTab === 'prediction' || this.activeTab === 'case_connections';
    }
  },
  watch: {
    'casesFilters.selectedPointOfLaw': function (newCode) {
      if (this.pointOfLawRef) {
        const currentValue = this.pointOfLawRef.getValue(true);
        if (newCode !== currentValue) {
          this.pointOfLawRef.setChoiceByValue(newCode);
          setTimeout(() => {
            this.getAllCasesTabFilters(false);
          }, 500);
        }
      }
    }
  },
  beforeUnmount() {
    eventBus.all().clear();
  },
  created() {
    if (this.isAuthenticated) {
      eventBus.on('company-change', (companyId) => {
        if (companyId && this.company_companyRef) {
          this.company_companyRef.setChoiceByValue(companyId)
        }
      });
      eventBus.on('on-search-cases', (query) => {
        this.searchQuery = query;
        setTimeout(() => {
          this.getAllCasesTabFilters(false);
        }, 500);
      });
      eventBus.on('on-case-details-change', (data) => {
        this.searchCaseQuery = data?.query;
      });
      this.getAllCasesTabFilters(true);
    }
  },
  methods: {
    isMobile,
    ...mapMutations("dashboard", [
      "updateCasesFilters",
      // "updateTrialFilter",
      // "updateCompanyFilters",
      // "updateIndustryFilters",
      "updateCaseDetailFilters",
      // "updateLNRFilters"
    ]),
    getCasesQueryFilter(ignoreCompanySelection = false) {
      const queryFilters = [];
      if (this.casesFilters.selectedCasesDate.length > 0) {
        const [startDate, endDate] = this.casesFilters.selectedCasesDate;
        let sDate = '', eDate = '';
        const dateFormat = 'YYYY-MM-DD';
        if (startDate) sDate = moment(startDate).format(dateFormat);
        if (endDate) eDate = moment(endDate).format(dateFormat);
        if (sDate || eDate) {
          const range = { caseDate: { boost: 2.0 } };
          if (sDate) range.caseDate['gte'] = sDate;
          if (sDate && eDate) range.caseDate['lte'] = eDate;
          if (sDate && !eDate) {
            const newSDate = moment(startDate);
            newSDate.add(1, 'day');
            range.caseDate['lte'] = newSDate.format(dateFormat);
          };
          queryFilters.push({ range });
        }
      }
      if (this.casesFilters.selectedIndustry) {
        queryFilters.push({ term: { 'industries.GroupShort.keyword': this.casesFilters.selectedIndustry } });
      }
      if (this.casesFilters.selectedSubIndustry) {
        queryFilters.push({ term: { 'industries.CodeDesc.keyword': this.casesFilters.selectedSubIndustry } });
      }
      if (!ignoreCompanySelection && this.casesFilters.selectedCompany) {
        queryFilters.push({ term: { 'companies.id.keyword': this.casesFilters.selectedCompany } });
      }
      if (this.casesFilters.selectedCity) {
        queryFilters.push({ term: { 'city.keyword': this.casesFilters.selectedCity } });
      }
      if (this.casesFilters.selectedResult) {
        queryFilters.push({ term: { 'result.keyword': this.casesFilters.selectedResult } });
      }
      if (this.casesFilters.selectedPointOfLaw) {
        queryFilters.push({ term: { 'category.keyword': this.casesFilters.selectedPointOfLaw } });
      }
      if (this.casesFilters.selectedJudge) {
        queryFilters.push({ term: { 'heardBefore.keyword': this.casesFilters.selectedJudge } });
      }
      if (this.casesFilters.selectedCaseType) {
        queryFilters.push({ term: { 'caseType.keyword': this.casesFilters.selectedCaseType } });
      }
      return queryFilters;
    },
    async getAllCasesTabFilters(isInitialLoad = false) {
      if (!this.isAuthenticated) {
        this.isResetting = false;
        return null;
      }
      try {
        const filterData = {
          size: 0,
          query: { bool: {} },
          aggs: casesTabFilterAggs(this.casesFilters.selectedIndustry, this.casesFilters.selectedSubIndustry),
        };
        const queryFilters = this.getCasesQueryFilter(false);
        if (this.searchQuery) {
          const searchQueryFilter = [];
          searchQueryFilter.push({ match_phrase_prefix: { caseName: { query: this.searchQuery, boost: 2 } } });
          searchQueryFilter.push({ match: { caseName: { query: this.searchQuery, boost: 1 } } });
          filterData.min_score = 1;
          filterData.query.bool.minimum_should_match = 1;
          filterData.query.bool.should = searchQueryFilter;
        }
        if (queryFilters.length) {
          filterData.query.bool.filter = queryFilters;
        }

        let allIndustries = [];
        if (this.casesFilters.selectedIndustry) {
          const indFilters = { size: 0, aggs: getOnlyIndustries };
          const indResponse = await elasticSearchApiInstance.post('/_search', clearEmpties(indFilters));
          allIndustries = indResponse?.data?.aggregations?.industries?.buckets ?? [];
        }

        const response = await elasticSearchApiInstance.post('/_search', clearEmpties(filterData));
        const { industries, subIndustries, caseTypes, cities, judges, pointOfLaws, results } = response?.data?.aggregations || {};

        const allInds = allIndustries.length > 0 ? allIndustries : (industries?.buckets ?? []);
        this.casesTabFilters = {
          ...this.casesTabFilters,
          industries: uniqBy(allInds.map(c => ({ label: c?.key ?? '', value: c?.key ?? '' })), 'value'),
          subIndustries: uniqBy((subIndustries?.buckets ?? []).map(c => ({ label: c?.key ?? '', value: c?.key ?? '' })), 'value'),
          caseTypes: uniqBy((caseTypes?.buckets ?? []).map(c => ({ label: c?.key ?? '', value: c?.key ?? '' })), 'value'),
          cities: uniqBy((cities?.buckets ?? []).map(c => ({ label: c?.key ?? '', value: c?.key ?? '' })), 'value'),
          judges: uniqBy((judges?.buckets ?? []).map(c => ({ label: c?.key ?? '', value: c?.key ?? '' })), 'value'),
          pointOfLaws: uniqBy((pointOfLaws?.buckets ?? []).map(c => ({ label: c?.key ?? '', value: c?.key ?? '' })), 'value'),
          results: uniqBy((results?.buckets ?? []).map(c => ({ label: c?.key ?? '', value: c?.key ?? '' })), 'value'),
        };
        this.dataMounted(isInitialLoad);
        setTimeout(() => {
          this.isResetting = false;
        }, 200);
      } catch (error) {
        setTimeout(() => {
          this.isResetting = false;
        }, 200);
        const errorMessage = handleError(error);
        if (errorMessage) {
          this.$swal({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: "btn bg-success",
            },
            buttonsStyling: false,
          });
        }
      }
    },
    dataMounted(initialLoad = false) {
      try {
        // Cases Tab
        if (initialLoad || this.activeTab === 'cases') {
          if (document.getElementById("filter-industries")) {
            if (this.industriesRef) {
              this.industriesRef.clearChoices();
              this.industriesRef.clearStore();
              this.industriesRef.destroy();
              this.industriesRef = null;
            }
            const industryChoices = sortBy(this.casesTabFilters.industries, 'label');
            industryChoices.unshift({ label: "All", value: "" });
            const industriesElement = document.getElementById("filter-industries");
            this.industriesRef = new Choices(industriesElement, {
              choices: industryChoices,
              shouldSort: false,
              itemSelectText: "",
              searchEnabled: true,
              searchChoices: true,
              searchFloor: 0,
              searchResultLimit: -1,
              searchFields: ['label'],
              fuseOptions: { threshold: 0 },
              searchPlaceholderValue: "Search Industries",
              classNames: {
                containerOuter: ["choices", "custom_menu_list"]
              }
            });
            this.industriesRef.setChoiceByValue(this.casesFilters.selectedIndustry);
          }
          if (document.getElementById("filter-sub-industries")) {
            if (this.subIndustryRef) {
              this.subIndustryRef.clearChoices();
              this.subIndustryRef.clearStore();
              this.subIndustryRef.destroy();
              this.subIndustryRef = null;
            }
            const subIndustriesChoices = sortBy(this.casesTabFilters.subIndustries, 'label');
            subIndustriesChoices.unshift({ label: "All", value: "" });
            const subIndustriesElement = document.getElementById("filter-sub-industries");
            this.subIndustryRef = new Choices(subIndustriesElement, {
              choices: subIndustriesChoices,
              shouldSort: false,
              itemSelectText: "",
              searchEnabled: true,
              searchChoices: true,
              searchFloor: 0,
              searchResultLimit: -1,
              searchFields: ['label'],
              fuseOptions: { threshold: 0 },
              searchPlaceholderValue: "Search Sub-Industries",
              classNames: {
                containerOuter: ["choices", "custom_menu_list"]
              }
            });
            this.subIndustryRef.setChoiceByValue(this.casesFilters.selectedSubIndustry);
          }
          // if (document.getElementById("company")) {
          //   const companyElement = document.getElementById("company");
          //   const companyChoices = sortBy(this.casesTabFilters.companies, 'label');
          //   companyChoices.unshift({ label: "All", value: "" });
          //   if (this.companyRef) {
          //     this.companyRef.clearChoices();
          //     this.companyRef.destroy();
          //     this.companyRef = null;
          //   }
          //   this.companyRef = new Choices(companyElement, {
          //     choices: companyChoices,
          //     shouldSort: false,
          //     itemSelectText: "",
          //     searchEnabled: true,
          //     searchChoices: true,
          //     searchFloor: 0,
          //     searchResultLimit: 100,
          //     renderChoiceLimit: 100,
          //     searchFields: ['label'],
          //     fuseOptions: { threshold: 0 },
          //     searchPlaceholderValue: "Search Companies",
          //     classNames: {
          //       containerOuter: ["choices", "custom_menu_list"]
          //     }
          //   });
          //   this.companyRef.setChoiceByValue(this.casesFilters.selectedCompany);
          // }
          if (document.getElementById("filter-cities")) {
            const cityChoices = sortBy(this.casesTabFilters.cities, 'label');
            cityChoices.unshift({ label: "All", value: "" });
            if (this.citiesRef) {
              this.citiesRef.clearChoices();
              this.citiesRef.clearStore();
              this.citiesRef.destroy();
              this.citiesRef = null;
            }
            const citiesElement = document.getElementById("filter-cities");
            this.citiesRef = new Choices(citiesElement, {
              choices: cityChoices,
              shouldSort: false,
              itemSelectText: "",
              searchEnabled: true,
              searchChoices: true,
              searchFloor: 0,
              searchResultLimit: -1,
              searchFields: ['label'],
              fuseOptions: { threshold: 0 },
              searchPlaceholderValue: "Search Cities",
              classNames: {
                containerOuter: ["choices", "custom_menu_list"]
              }
            });
            this.citiesRef.setChoiceByValue(this.casesFilters.selectedCity);
          }
          if (document.getElementById("filter-result")) {
            const resultChoices = sortBy(this.casesTabFilters.results, 'label');
            resultChoices.unshift({ label: "All", value: "" });
            if (this.resultRef) {
              this.resultRef.clearChoices();
              this.resultRef.clearStore();
              this.resultRef.destroy();
              this.resultRef = null;
            }
            const resultElement = document.getElementById("filter-result");
            this.resultRef = new Choices(resultElement, {
              choices: resultChoices,
              shouldSort: false,
              itemSelectText: "",
              searchEnabled: true,
              searchChoices: true,
              searchFloor: 0,
              searchResultLimit: -1,
              searchFields: ['label'],
              fuseOptions: { threshold: 0 },
              searchPlaceholderValue: "Search Results",
              classNames: {
                containerOuter: ["choices", "custom_menu_list"]
              }
            });
            this.resultRef.setChoiceByValue(this.casesFilters.selectedResult);
          }
          if (document.getElementById("filter-point_of_law")) {
            const pointOfLawChoices = sortBy(this.casesTabFilters.pointOfLaws, 'label');
            pointOfLawChoices.unshift({ label: "All", value: "" });
            if (this.pointOfLawRef) {
              this.pointOfLawRef.clearChoices();
              this.pointOfLawRef.clearStore();
              this.pointOfLawRef.destroy();
              this.pointOfLawRef = null;
            }
            const pointOfLawCodeElement = document.getElementById("filter-point_of_law");
            this.pointOfLawRef = new Choices(pointOfLawCodeElement, {
              choices: pointOfLawChoices,
              shouldSort: false,
              itemSelectText: "",
              searchEnabled: true,
              searchChoices: true,
              searchFloor: 0,
              searchResultLimit: -1,
              searchFields: ['label'],
              fuseOptions: { threshold: 0 },
              searchPlaceholderValue: "Search Point of Law",
              classNames: {
                containerOuter: ["choices", "custom_menu_list"]
              }
            });
            this.pointOfLawRef.setChoiceByValue(this.casesFilters.selectedPointOfLaw);
          }
          if (document.getElementById("filter-judge")) {
            const judgeChoices = sortBy(this.casesTabFilters.judges, 'label');
            judgeChoices.unshift({ label: "All", value: "" });
            if (this.judgeRef) {
              this.judgeRef.clearChoices();
              this.judgeRef.clearStore();
              this.judgeRef.destroy();
              this.judgeRef = null;
            }
            const judgeElement = document.getElementById("filter-judge");
            this.judgeRef = new Choices(judgeElement, {
              choices: judgeChoices,
              shouldSort: false,
              itemSelectText: "",
              searchEnabled: true,
              searchChoices: true,
              searchFloor: 0,
              searchResultLimit: -1,
              searchFields: ['label'],
              fuseOptions: { threshold: 0 },
              searchPlaceholderValue: "Search Judge",
              classNames: {
                containerOuter: ["choices", "custom_menu_list"]
              }
            });
            this.judgeRef.setChoiceByValue(this.casesFilters.selectedJudge);
          }
          if (document.getElementById("filter-case_type")) {
            const caseTypeChoices = sortBy(this.casesTabFilters.caseTypes, 'label');
            caseTypeChoices.unshift({ label: "All", value: "" });
            if (this.caseTypeRef) {
              this.caseTypeRef.clearChoices();
              this.caseTypeRef.clearStore();
              this.caseTypeRef.destroy();
              this.caseTypeRef = null;
            }
            const caseTypeElement = document.getElementById("filter-case_type");
            this.caseTypeRef = new Choices(caseTypeElement, {
              choices: caseTypeChoices,
              shouldSort: false,
              itemSelectText: "",
              searchEnabled: true,
              searchChoices: true,
              searchFloor: 0,
              searchResultLimit: -1,
              searchFields: ['label'],
              fuseOptions: { threshold: 0 },
              searchPlaceholderValue: "Search Case Type",
              classNames: {
                containerOuter: ["choices", "custom_menu_list"]
              }
            });
            this.caseTypeRef.setChoiceByValue(this.casesFilters.selectedCaseType);
          }
        }
        // if (initialLoad || this.activeTab === 'case_details') {
        // // Company Tab
        // if (document.getElementById("company_company")) {
        //   const companyChoices = this.companies.map(c => ({ label: c.name, value: String(c.id || '') }));
        //   if (this.company_companyRef) {
        //     this.company_companyRef.setChoices(companyChoices, "value", "label", true);
        //   } else {
        //     const companyElement = document.getElementById("company_company");
        //     this.company_companyRef = new Choices(companyElement, {
        //       choices: companyChoices,
        //       shouldSort: false,
        //       itemSelectText: "",
        //       searchEnabled: true,
        //       searchChoices: true,
        //       searchFloor: 0,
        //       searchResultLimit: -1,
        //       searchFields: ['label'],
        //       fuseOptions: { threshold: 0 },
        //       searchPlaceholderValue: "Search Companies",
        //       classNames: {
        //         containerOuter: ["choices", "custom_menu_list"]
        //       }
        //     });
        //   }
        //   this.company_companyRef.setChoiceByValue(this.companyFilters.selectedCompany)
        // }
        // }
        // if (initialLoad || this.activeTab === 'industry') {
        // // Industry Tab
        // if (document.getElementById("industry_years")) {
        //   const yearChoices = orderBy(
        //     uniqBy(
        //       this.cases.filter(c => c.caseDate && moment(c.caseDate, 'YYYY-MM-DD HH:mm').isValid())
        //         .map(cs => ({
        //           label: moment(cs.caseDate, 'YYYY-MM-DD HH:mm').format("YYYY"),
        //           value: moment(cs.caseDate, 'YYYY-MM-DD HH:mm').format("YYYY"),
        //         })), 'value'),
        //     'value',
        //     'desc'
        //   );
        //   yearChoices.unshift({ label: "Select Year", value: "" });
        //   if (this.industry_yearsRef) {
        //     this.industry_yearsRef.setChoices(yearChoices, "value", "label", true);
        //   } else {
        //     const industryYears = document.getElementById("industry_years");
        //     this.industry_yearsRef = new Choices(industryYears, {
        //       choices: yearChoices,
        //       shouldSort: false,
        //       itemSelectText: "",
        //       searchEnabled: true,
        //       searchChoices: true,
        //       searchFloor: 0,
        //       searchResultLimit: -1,
        //       searchFields: ['label'],
        //       fuseOptions: { threshold: 0 },
        //       searchPlaceholderValue: "Search Years",
        //       classNames: {
        //         containerOuter: ["choices", "custom_menu_list"]
        //       }
        //     });
        //   }
        //   this.industry_yearsRef.setChoiceByValue(this.industryFilters.selectedYear);
        // }
        // if (document.getElementById("industry_industries")) {
        //   const industriesData = this.getIndustry_Industries().flatMap(ind => ind.industryData).filter(f => !!f?.GroupDesc);
        //   const indChoices = sortBy(uniqBy(industriesData.map(cs => ({
        //     label: cs.GroupDesc,
        //     value: cs.GroupDesc,
        //   })), 'value'), 'value');
        //   indChoices.unshift({ label: "Select Industries", value: "" });
        //   if (this.industry_industriesRef) {
        //     this.industry_industriesRef.setChoices(indChoices, "value", "label", true);
        //   } else {
        //     const industryIndustries = document.getElementById("industry_industries");
        //     this.industry_industriesRef = new Choices(industryIndustries, {
        //       choices: indChoices,
        //       shouldSort: false,
        //       itemSelectText: "",
        //       searchEnabled: true,
        //       searchChoices: true,
        //       searchFloor: 0,
        //       searchResultLimit: -1,
        //       searchFields: ['label'],
        //       fuseOptions: { threshold: 0 },
        //       searchPlaceholderValue: "Search Industries",
        //       classNames: {
        //         containerOuter: ["choices", "custom_menu_list"]
        //       }
        //     });
        //   }
        //   this.industry_industriesRef.setChoiceByValue(this.industryFilters.selectedIndustry);
        // }
        // if (document.getElementById("industry_sub-industries")) {
        //   const subIndustriesData = this.getIndustry_SubIndustries().flatMap(ind => ind.industryData).filter(f => !!f?.CodeDesc);
        //   const subIndChoices = sortBy(uniqBy(subIndustriesData.map(cs => ({
        //     label: cs.CodeDesc,
        //     value: cs.CodeDesc,
        //   })), 'value'), 'value');
        //   subIndChoices.unshift({ label: "Select Sub-Industries", value: "" })
        //   if (this.industry_sub_industriesRef) {
        //     this.industry_sub_industriesRef.setChoices(subIndChoices, "value", "label", true);
        //   } else {
        //     const industrySubIndustries = document.getElementById("industry_sub-industries");
        //     this.industry_sub_industriesRef = new Choices(industrySubIndustries, {
        //       choices: subIndChoices,
        //       shouldSort: false,
        //       itemSelectText: "",
        //       searchEnabled: true,
        //       searchChoices: true,
        //       searchFloor: 0,
        //       searchResultLimit: -1,
        //       searchFields: ['label'],
        //       fuseOptions: { threshold: 0 },
        //       searchPlaceholderValue: "Search Sub-Industries",
        //       classNames: {
        //         containerOuter: ["choices", "custom_menu_list"]
        //       }
        //     });
        //   }
        //   this.industry_sub_industriesRef.setChoiceByValue(this.industryFilters.selectedSubIndustry);
        // }
        // if (document.getElementById("industry_case_type")) {
        //   const caseTypeChoices = sortBy(uniqBy(this.getIndustry_CaseTypes().map(cs => ({
        //     label: cs.caseType,
        //     value: cs.caseType,
        //   })), 'value'), 'value');
        //   caseTypeChoices.unshift({ label: "Select Case Type", value: "" });
        //   if (this.industry_caseTypeRef) {
        //     this.industry_caseTypeRef.setChoices(caseTypeChoices, "value", "label", true);
        //   } else {
        //     const industrySubIndustries = document.getElementById("industry_case_type");
        //     this.industry_caseTypeRef = new Choices(industrySubIndustries, {
        //       choices: caseTypeChoices,
        //       shouldSort: false,
        //       itemSelectText: "",
        //       searchEnabled: true,
        //       searchChoices: true,
        //       searchFloor: 0,
        //       searchResultLimit: -1,
        //       searchFields: ['label'],
        //       fuseOptions: { threshold: 0 },
        //       searchPlaceholderValue: "Search Case Type",
        //       classNames: {
        //         containerOuter: ["choices", "custom_menu_list"]
        //       }
        //     });
        //   }
        //   this.industry_caseTypeRef.setChoiceByValue(this.industryFilters.selectedCaseType);
        // }
        // }
        // // Legislation Tab
        // if (document.getElementById("tribunal_type")) {
        //   const tribunalTypeChoices = sortBy(uniqBy(this.cases.map(i => ({
        //     label: i.caseType,
        //     value: i.caseType,
        //   })), 'value'), 'value');
        //   tribunalTypeChoices.unshift({ label: "All", value: "" });
        //   if (this.tribunalTypeRef) {
        //     this.tribunalTypeRef.setChoices(tribunalTypeChoices, "value", "label", true);
        //   } else {
        //     const tribunalTypeElement = document.getElementById("tribunal_type");
        //     this.tribunalTypeRef = new Choices(tribunalTypeElement, {
        //       choices: tribunalTypeChoices,
        //       shouldSort: false,
        //       itemSelectText: "",
        //       searchEnabled: true,
        //       searchChoices: true,
        //       searchFloor: 0,
        //       searchResultLimit: -1,
        //       searchFields: ['label'],
        //       fuseOptions: { threshold: 0 },
        //       searchPlaceholderValue: "Search Tribunal Type",
        //       classNames: {
        //         containerOuter: ["choices", "custom_menu_list"]
        //       }
        //     });
        //   }
        //   this.tribunalTypeRef.setChoiceByValue(this.lnrFilters.selectedTribunalType);
        // }
        // if (document.getElementById("law_type")) {
        //   const lawTypeChoices = sortBy(uniqBy(this.lawAndRegs.filter(l => (l?.lawType || '').trim()).map(i => ({
        //     label: i.lawType,
        //     value: i.lawType,
        //   })), 'value'), 'value');
        //   lawTypeChoices.unshift({ label: "All", value: "" });
        //   if (this.lawTypeRef) {
        //     this.lawTypeRef.setChoices(lawTypeChoices, "value", "label", true);
        //   } else {
        //     const lawTypeElement = document.getElementById("law_type");
        //     this.lawTypeRef = new Choices(lawTypeElement, {
        //       choices: lawTypeChoices,
        //       shouldSort: false,
        //       itemSelectText: "",
        //       searchEnabled: true,
        //       searchChoices: true,
        //       searchFloor: 0,
        //       searchResultLimit: -1,
        //       searchFields: ['label'],
        //       fuseOptions: { threshold: 0 },
        //       searchPlaceholderValue: "Search Law Type",
        //       classNames: {
        //         containerOuter: ["choices", "custom_menu_list"]
        //       }
        //     });
        //   }
        //   this.lawTypeRef.setChoiceByValue(this.lnrFilters.selectedlawType);
        // }
        // // Trials Tab
        // if (document.getElementById("trials_type")) {
        //   const trialsTypeChoices = sortBy(uniqBy(
        //     this.getTrialsTypeWise().filter(d => d.trial_type).map(td => ({ label: td.trial_type, value: td.trial_type })),
        //     'value',
        //   ), 'value');
        //   trialsTypeChoices.unshift({ label: "All", value: "" });
        //   if (this.trialsTypeRef) {
        //     this.trialsTypeRef.setChoices(trialsTypeChoices, "value", "label", true);
        //   } else {
        //     const trialsType = document.getElementById("trials_type");
        //     this.trialsTypeRef = new Choices(trialsType, {
        //       choices: trialsTypeChoices,
        //       shouldSort: false,
        //       itemSelectText: "",
        //       searchEnabled: true,
        //       searchChoices: true,
        //       searchFloor: 0,
        //       searchResultLimit: -1,
        //       searchFields: ['label'],
        //       fuseOptions: { threshold: 0 },
        //       searchPlaceholderValue: "Search Types",
        //       classNames: {
        //         containerOuter: ["choices", "custom_menu_list"]
        //       }
        //     });
        //   }
        //   this.trialsTypeRef.setChoiceByValue(this.trialFilters.selectedTrialType)
        // }
        // if (document.getElementById("trials_hearing_time")) {
        //   const trialsHearingTimeChoices = sortBy(uniqBy(
        //     this.getTrialsHearingTimeWise().filter(d => d['Jurisdiction(s)'] && d.Time)
        //       .map(td => ({ label: td.Time, value: td.Time })),
        //     'value',
        //   ), 'value');
        //   trialsHearingTimeChoices.unshift({ label: "All", value: "" });
        //   if (this.trialsHearingTimeRef) {
        //     this.trialsHearingTimeRef.setChoices(trialsHearingTimeChoices, "value", "label", true);
        //   } else {
        //     const trialsHearingTime = document.getElementById("trials_hearing_time");
        //     this.trialsHearingTimeRef = new Choices(trialsHearingTime, {
        //       choices: trialsHearingTimeChoices,
        //       shouldSort: false,
        //       itemSelectText: "",
        //       searchEnabled: true,
        //       searchChoices: true,
        //       searchFloor: 0,
        //       searchResultLimit: -1,
        //       searchFields: ['label'],
        //       fuseOptions: { threshold: 0 },
        //       searchPlaceholderValue: "Search Hearing Time",
        //       classNames: {
        //         containerOuter: ["choices", "custom_menu_list"]
        //       }
        //     });
        //   }
        //   if (this.trialFilters.selectedTrialHearingTime && this.trialsHearingTimeRef) {
        //     this.trialsHearingTimeRef.setChoiceByValue(this.trialFilters.selectedTrialHearingTime)
        //   }
        // }
        // if (document.getElementById("trials_venue")) {
        //   const trialsVenueChoices = sortBy(uniqBy(
        //     this.getTrialsVenueWise().filter(d => d.Venue).map(td => ({ label: td.Venue, value: td.Venue })),
        //     'value',
        //   ), 'value');
        //   trialsVenueChoices.unshift({ label: "All", value: "" });
        //   if (this.trialsVenueRef) {
        //     this.trialsVenueRef.setChoices(trialsVenueChoices, "value", "label", true);
        //   } else {
        //     const trialsVenue = document.getElementById("trials_venue");
        //     this.trialsVenueRef = new Choices(trialsVenue, {
        //       choices: trialsVenueChoices,
        //       shouldSort: false,
        //       itemSelectText: "",
        //       searchEnabled: true,
        //       searchChoices: true,
        //       searchFloor: 0,
        //       searchResultLimit: -1,
        //       searchFields: ['label'],
        //       fuseOptions: { threshold: 0 },
        //       searchPlaceholderValue: "Search Venue",
        //       classNames: {
        //         containerOuter: ["choices", "custom_menu_list"]
        //       }
        //     });
        //   }
        //   this.trialsVenueRef.setChoiceByValue(this.trialFilters.selectedTrialVenue)
        // }
        // if (document.getElementById("trials_caseno")) {
        //   const trialsCaseNoChoices = sortBy(uniqBy(
        //     this.getTrialsCaseNoWise().filter(d => d['Case No.']).map(td => ({ label: td['Case No.'], value: td['Case No.'] })),
        //     'value',
        //   ), 'value');
        //   trialsCaseNoChoices.unshift({ label: "All", value: "" });
        //   if (this.trialsCaseNoRef) {
        //     this.trialsCaseNoRef.setChoices(trialsCaseNoChoices, "value", "label", true);
        //   } else {
        //     const trialsCaseNo = document.getElementById("trials_caseno");
        //     this.trialsCaseNoRef = new Choices(trialsCaseNo, {
        //       choices: trialsCaseNoChoices,
        //       shouldSort: false,
        //       itemSelectText: "",
        //       searchEnabled: true,
        //       searchChoices: true,
        //       searchFloor: 0,
        //       searchResultLimit: -1,
        //       searchFields: ['label'],
        //       fuseOptions: { threshold: 0 },
        //       searchPlaceholderValue: "Search Case No",
        //       classNames: {
        //         containerOuter: ["choices", "custom_menu_list"]
        //       }
        //     });
        //   }
        //   this.trialsCaseNoRef.setChoiceByValue(this.trialFilters.selectedTrialCaseNo)
        // }
        // if (document.getElementById("trials_point_of_law")) {
        //   const trialsPointOfLawChoices = uniqBy(
        //     this.getTrialsPointOfLawWise().flatMap(trlDt => trlDt['Jurisdiction(s)'].split(','))
        //       .map(code => ({ label: code.trim(), value: code.trim() })),
        //     'value',
        //   );
        //   trialsPointOfLawChoices.unshift({ label: "All", value: "" });
        //   if (this.trialsPointOfLawRef) {
        //     this.trialsPointOfLawRef.setChoices(trialsPointOfLawChoices, "value", "label", true);
        //   } else {
        //     const trialsPointOfLaw = document.getElementById("trials_point_of_law");
        //     this.trialsPointOfLawRef = new Choices(trialsPointOfLaw, {
        //       choices: trialsPointOfLawChoices,
        //       shouldSort: false,
        //       itemSelectText: "",
        //       searchEnabled: true,
        //       searchChoices: true,
        //       searchFloor: 0,
        //       searchResultLimit: -1,
        //       searchFields: ['label'],
        //       fuseOptions: { threshold: 0 },
        //       searchPlaceholderValue: "Search Point of Law",
        //       classNames: {
        //         containerOuter: ["choices", "custom_menu_list"]
        //       }
        //     });
        //   }
        //   this.trialsPointOfLawRef.setChoiceByValue(this.trialFilters.selectedTrialPointOfLaw);
        // }
        // if (document.getElementById("trials_claimant")) {
        //   const trialsClaimantChoices = sortBy(uniqBy(
        //     this.getTrialsClaimantWise().map(clmnt => ({
        //       label: (clmnt.Claimant || '').replace(/\n/g, ', '),
        //       value: (clmnt.Claimant || '').split("\n")[0]
        //     })),
        //     'value',
        //   ), 'value');
        //   trialsClaimantChoices.unshift({ label: "All", value: "" });
        //   if (this.trialsClaimantRef) {
        //     this.trialsClaimantRef.setChoices(trialsClaimantChoices, "value", "label", true);
        //   } else {
        //     const trialsClaimant = document.getElementById("trials_claimant");
        //     this.trialsClaimantRef = new Choices(trialsClaimant, {
        //       choices: trialsClaimantChoices,
        //       shouldSort: false,
        //       itemSelectText: "",
        //       searchEnabled: true,
        //       searchChoices: true,
        //       searchFloor: 0,
        //       searchResultLimit: -1,
        //       searchFields: ['label'],
        //       fuseOptions: { threshold: 0 },
        //       searchPlaceholderValue: "Search Claimant",
        //       classNames: {
        //         containerOuter: ["choices", "custom_menu_list"]
        //       }
        //     });
        //   }
        //   this.trialsClaimantRef.setChoiceByValue(this.trialFilters.selectedTrialClaimant);
        // }
      } catch (error) {
        if (process.env.NODE_ENV == 'development') {
          // console.log(error);
        }
      }
    },
    // getIndustry_Industries() {
    //   return this.cases.filter(
    //     cs => (
    //       !this.industryFilters.selectedYear
    //       || (cs.caseDate && moment(cs.caseDate, 'YYYY-MM-DD HH:mm').isSame(this.industryFilters.selectedYear, 'year'))
    //     )
    //   );
    // },
    // getIndustry_SubIndustries() {
    //   return this.getIndustry_Industries().filter(
    //     cs => (
    //       !this.industryFilters.selectedIndustry
    //       || (cs.industryData || []).some(ind => ind?.GroupDesc == this.industryFilters.selectedIndustry)
    //     )
    //   );
    // },
    // getIndustry_CaseTypes() {
    //   return this.getIndustry_SubIndustries().filter(
    //     cs => (
    //       !this.industryFilters.selectedSubIndustry
    //       || (cs.industryData || []).some(ind => ind?.CodeDesc == this.industryFilters.selectedSubIndustry)
    //     )
    //   );
    // },
    changeIndustryOptions(type) {
      if (type == 'year' && this.industry_industriesRef) {
        this.industry_industriesRef.clearChoices();
        this.industry_industriesRef.clearStore();
        const industriesData = this.getIndustry_Industries().flatMap(ind => ind.industryData).filter(f => !!f?.GroupDesc);
        const indChoices = sortBy(uniqBy(industriesData.map(cs => ({
          label: cs.GroupDesc,
          value: cs.GroupDesc,
        })), 'value'), 'value');
        indChoices.unshift({ label: "Select Industries", value: "" })
        this.industry_industriesRef.setChoices(indChoices, "value", "label", true);
        this.industry_industriesRef.setChoiceByValue('');
      }
      if (
        (type == 'year' || type == 'industry')
        && this.industry_sub_industriesRef
      ) {
        this.industry_sub_industriesRef.clearChoices();
        this.industry_sub_industriesRef.clearStore();
        const subIndustriesData = this.getIndustry_SubIndustries().flatMap(ind => ind.industryData).filter(f => !!f?.CodeDesc);
        const subIndChoices = sortBy(uniqBy(subIndustriesData.map(cs => ({
          label: cs.CodeDesc,
          value: cs.CodeDesc,
        })), 'value'), 'value');
        subIndChoices.unshift({ label: "Select Sub-Industries", value: "" })
        this.industry_sub_industriesRef.setChoices(subIndChoices, "value", "label", true);
        this.industry_sub_industriesRef.setChoiceByValue('');
      }
      if (
        (type == 'year' || type == 'industry' || type == 'sub-industry')
        && this.industry_caseTypeRef
      ) {
        this.industry_caseTypeRef.clearChoices();
        this.industry_caseTypeRef.clearStore();

        const caseTypeChoices = sortBy(uniqBy(this.getIndustry_CaseTypes().map(cs => ({
          label: cs.caseType,
          value: cs.caseType,
        })), 'value'), 'value');
        caseTypeChoices.unshift({ label: "Select Case Type", value: "" });
        this.industry_caseTypeRef.setChoices(caseTypeChoices, "value", "label", true);
        this.industry_caseTypeRef.setChoiceByValue("");
      }
    },
    onCasesDateChange(date) {
      const dates = (date || []).filter(d => d);
      this.updateCasesFilters({ ...this.casesFilters, selectedCasesDate: dates });
      setTimeout(() => {
        this.getAllCasesTabFilters(false);
      }, 500);
    },
    onIndustryChange(value) {
      this.updateCasesFilters({ ...this.casesFilters, selectedIndustry: value });
      setTimeout(() => {
        this.getAllCasesTabFilters(false);
      }, 500);
    },
    onSubIndustryChange(value) {
      this.updateCasesFilters({ ...this.casesFilters, selectedSubIndustry: value });
      setTimeout(() => {
        this.getAllCasesTabFilters(false);
      }, 500);
    },
    onCompanyChange(item) {
      this.searchCompanyQuery = item?.name ?? '';
      this.updateCasesFilters({ ...this.casesFilters, selectedCompany: item?.id ?? '' });
      setTimeout(() => {
        this.getAllCasesTabFilters(false);
      }, 500);
    },
    onCityChange(value) {
      this.updateCasesFilters({ ...this.casesFilters, selectedCity: value });
      setTimeout(() => {
        this.getAllCasesTabFilters(false);
      }, 500);
    },
    onResultChange(value) {
      this.updateCasesFilters({ ...this.casesFilters, selectedResult: value });
      setTimeout(() => {
        this.getAllCasesTabFilters(false);
      }, 500);
    },
    onPointOfLawChange(value) {
      this.updateCasesFilters({ ...this.casesFilters, selectedPointOfLaw: value });
      setTimeout(() => {
        this.getAllCasesTabFilters(false);
      }, 500);
    },
    onJudgeChange(value) {
      this.updateCasesFilters({ ...this.casesFilters, selectedJudge: value });
      setTimeout(() => {
        this.getAllCasesTabFilters(false);
      }, 500);
    },
    onCaseTypeChange(value) {
      this.updateCasesFilters({ ...this.casesFilters, selectedCaseType: value });
      setTimeout(() => {
        this.getAllCasesTabFilters(false);
      }, 500);
    },
    casesDatePickerformat(dates) {
      return (dates || []).filter(d => d).map(date => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
      }).join(" to ");
    },
    onCaseDetailsCaseChange(item) {
      this.updateCaseDetailFilters({ selectedCase: item.decisionID });
      this.searchCaseQuery = item.caseName;
    },
    // onCompanyCompanyChange(value) {
    //   this.updateCompanyFilters(({ selectedCompany: value }));
    // },
    // onIndustryYearChange(value) {
    //   this.updateIndustryFilters({
    //     ...this.industryFilters,
    //     selectedYear: value,
    //     selectedIndustry: '',
    //     selectedSubIndustry: '',
    //     selectedCaseType: '',
    //   });
    //   this.changeIndustryOptions('year');
    // },
    // onIndustryIndustriesChange(value) {
    //   this.updateIndustryFilters({
    //     ...this.industryFilters,
    //     selectedIndustry: value,
    //     selectedSubIndustry: '',
    //     selectedCaseType: '',
    //   });
    //   this.changeIndustryOptions('industry');
    // },
    // onIndustrySubIndustryChange(value) {
    //   this.updateIndustryFilters({
    //     ...this.industryFilters,
    //     selectedSubIndustry: value,
    //     selectedCaseType: '',
    //   });
    //   this.changeIndustryOptions('sub-industry');
    // },
    // onIndustryCaseTypeChange(value) {
    //   this.updateIndustryFilters({ ...this.industryFilters, selectedCaseType: value });
    // },
    // onLNRTribunalTypeChange(value) {
    //   this.updateLNRFilters({ ...this.lnrFilters, selectedTribunalType: value });
    // },
    // onLNRLawTypeChange(value) {
    //   this.updateLNRFilters({ ...this.lnrFilters, selectedLawType: value });
    // },
    // changeTrialsOptions(type) {
    //   if (type === 'trials_date' && this.trialsTypeRef) {
    //     this.trialsTypeRef.clearChoices();
    //     this.trialsTypeRef.clearStore();
    //     const trialsTypeChoices = sortBy(uniqBy(
    //       this.getTrialsTypeWise().filter(d => d.trial_type).map(td => ({ label: td.trial_type, value: td.trial_type })),
    //       'value',
    //     ), 'value');
    //     trialsTypeChoices.unshift({ label: "All", value: "" });
    //     this.trialsTypeRef.setChoices(trialsTypeChoices, "value", "label", true);
    //     this.trialsTypeRef.setChoiceByValue('');
    //   }
    //   if (
    //     (type === 'trials_date' || type == 'trials_type')
    //     && this.trialsHearingTimeRef
    //   ) {
    //     this.trialsHearingTimeRef.clearChoices();
    //     this.trialsHearingTimeRef.clearStore();
    //     const trialsHearingTimeChoices = sortBy(uniqBy(
    //       this.getTrialsHearingTimeWise().filter(d => d['Jurisdiction(s)'] && d.Time)
    //         .map(td => ({ label: td.Time, value: td.Time })),
    //       'value',
    //     ), 'value');
    //     trialsHearingTimeChoices.unshift({ label: "All", value: "" });
    //     this.trialsHearingTimeRef.setChoices(trialsHearingTimeChoices, "value", "label", true);
    //     this.trialsHearingTimeRef.setChoiceByValue('');
    //   }
    //   if (
    //     (type === 'trials_date' || type == 'trials_type' || type == 'trials_hearing_time')
    //     && this.trialsVenueRef
    //   ) {
    //     this.trialsVenueRef.clearChoices();
    //     this.trialsVenueRef.clearStore();
    //     const trialsVenueChoices = sortBy(uniqBy(
    //       this.getTrialsVenueWise().filter(d => d.Venue).map(td => ({ label: td.Venue, value: td.Venue })),
    //       'value',
    //     ), 'value');
    //     trialsVenueChoices.unshift({ label: "All", value: "" });
    //     this.trialsVenueRef.setChoices(trialsVenueChoices, "value", "label", true);
    //     this.trialsVenueRef.setChoiceByValue('');
    //   }
    //   if (
    //     (type === 'trials_date' || type == 'trials_type' || type == 'trials_hearing_time' || type === 'trials_venue')
    //     && this.trialsCaseNoRef
    //   ) {
    //     this.trialsCaseNoRef.clearChoices();
    //     this.trialsCaseNoRef.clearStore();
    //     const trialsCaseNoChoices = sortBy(uniqBy(
    //       this.getTrialsCaseNoWise().filter(d => d['Case No.']).map(td => ({ label: td['Case No.'], value: td['Case No.'] })),
    //       'value',
    //     ), 'value');
    //     trialsCaseNoChoices.unshift({ label: "All", value: "" });
    //     this.trialsCaseNoRef.setChoices(trialsCaseNoChoices, "value", "label", true);
    //     this.trialsCaseNoRef.setChoiceByValue('');
    //   }
    //   if (
    //     (type === 'trials_date' || type == 'trials_type' || type == 'trials_hearing_time' || type === 'trials_venue') || type === 'trials_caseno'
    //     && this.trialsPointOfLawRef
    //   ) {
    //     this.trialsPointOfLawRef.clearChoices();
    //     this.trialsPointOfLawRef.clearStore();
    //     const trialsPointOfLawChoices = uniqBy(
    //       this.getTrialsPointOfLawWise().flatMap(trlDt => trlDt['Jurisdiction(s)'].split(','))
    //         .map(code => ({ label: code.trim(), value: code.trim() })),
    //       'value',
    //     );
    //     trialsPointOfLawChoices.unshift({ label: "All", value: "" });
    //     this.trialsPointOfLawRef.setChoices(trialsPointOfLawChoices, "value", "label", true);
    //     this.trialsPointOfLawRef.setChoiceByValue('');
    //   }
    //   if (
    //     (type === 'trials_date' || type == 'trials_type' || type == 'trials_hearing_time' || type === 'trials_venue') || type === 'trials_caseno' || type === 'trials_point_of_law'
    //     && this.trialsClaimantRef
    //   ) {
    //     this.trialsClaimantRef.clearChoices();
    //     this.trialsClaimantRef.clearStore();
    //     const trialsClaimantChoices = sortBy(uniqBy(
    //       this.getTrialsClaimantWise().map(clmnt => ({
    //         label: (clmnt.Claimant || '').replace(/\n/g, ', '),
    //         value: (clmnt.Claimant || '').split("\n")[0]
    //       })),
    //       'value',
    //     ), 'value');
    //     trialsClaimantChoices.unshift({ label: "All", value: "" });
    //     this.trialsClaimantRef.setChoices(trialsClaimantChoices, "value", "label", true);
    //     this.trialsClaimantRef.setChoiceByValue('');
    //   }
    // },
    // getTrialsTypeWise() {
    //   const allTrials = [...this.trialsData].map(d => ({
    //     ...d,
    //     'Hearing Date': d['Hearing Date'] && d['Hearing Date'] != 'NaT' ? d['Hearing Date'] : '',
    //     'Time': d['Time'] && d['Time'] != 'NaT' ? moment(d['Time'].split('.')[0], 'HH:mm:ss').format("HH:mm") : '',
    //   })).filter(
    //     trl => (
    //       this.trialFilters.selectedTrialDate.length == 0
    //       || (this.trialFilters.selectedTrialDate.length > 0 && (trl['Hearing Date'] || '') && isDateBetween(this.trialFilters.selectedTrialDate, trl['Hearing Date']))
    //     )
    //   );
    //   return allTrials;
    // },
    // getTrialsHearingTimeWise() {
    //   return this.getTrialsTypeWise().filter(trl =>
    //     (trl.Time || '')
    //     && (
    //       this.trialFilters.selectedTrialHearingTime === ''
    //       || this.trialFilters.selectedTrialHearingTime === trl.Time
    //     )
    //   );
    // },
    // getTrialsVenueWise() {
    //   return this.getTrialsHearingTimeWise().filter(trl =>
    //     (trl.Venue || '')
    //     && (
    //       this.trialFilters.selectedTrialVenue === ''
    //       || this.trialFilters.selectedTrialVenue === trl.Venue
    //     )
    //   );
    // },
    // getTrialsCaseNoWise() {
    //   return this.getTrialsVenueWise().filter(trl =>
    //     (trl['Case No.'] || '')
    //     && (
    //       this.trialFilters.selectedTrialCaseNo === ''
    //       || this.trialFilters.selectedTrialCaseNo === trl['Case No.']
    //     )
    //   );
    // },
    // getTrialsPointOfLawWise() {
    //   return this.getTrialsCaseNoWise().filter(trl =>
    //     (trl['Jurisdiction(s)'] || '')
    //     && (
    //       this.trialFilters.selectedTrialPointOfLaw === ''
    //       || trl['Jurisdiction(s)'].includes(this.trialFilters.selectedTrialPointOfLaw)
    //     )
    //   );
    // },
    // getTrialsClaimantWise() {
    //   return this.getTrialsPointOfLawWise().filter(trl =>
    //     (trl.Claimant || '')
    //     && (
    //       this.trialFilters.selectedTrialClaimant === ''
    //       || (trl.Claimant || '').toLowerCase().includes(this.trialFilters.selectedTrialClaimant.toLowerCase())
    //     )
    //   );
    // },
    // onTrialsDateChange(date) {
    //   const dates = (date || []).filter(d => d);
    //   this.updateTrialFilter({
    //     ...this.trialFilters,
    //     selectedTrialType: '',
    //     selectedTrialVenue: '',
    //     selectedTrialCaseNo: '',
    //     selectedTrialClaimant: '',
    //     selectedTrialHearingTime: '',
    //     selectedTrialPointOfLaw: '',
    //     selectedTrialDate: dates,
    //   });
    //   setTimeout(() => {
    //     this.changeTrialsOptions('trials_date');
    //   }, 1000);
    // },
    // onTrialsTypeChange(e) {
    //   this.updateTrialFilter({
    //     ...this.trialFilters,
    //     selectedTrialVenue: '',
    //     selectedTrialCaseNo: '',
    //     selectedTrialClaimant: '',
    //     selectedTrialPointOfLaw: '',
    //     selectedTrialHearingTime: '',
    //     selectedTrialType: e.target.value,
    //   });
    //   setTimeout(() => {
    //     this.changeTrialsOptions('trials_type');
    //   }, 1000);
    // },
    // onTrialsHearingTimeChange(e) {
    //   this.updateTrialFilter({
    //     ...this.trialFilters,
    //     selectedTrialVenue: '',
    //     selectedTrialCaseNo: '',
    //     selectedTrialClaimant: '',
    //     selectedTrialPointOfLaw: '',
    //     selectedTrialHearingTime: e.target.value,
    //   });
    //   setTimeout(() => {
    //     this.changeTrialsOptions('trials_hearing_time');
    //   }, 1000);
    // },
    // onTrialsVenueChange(e) {
    //   this.updateTrialFilter({
    //     ...this.trialFilters,
    //     selectedTrialCaseNo: '',
    //     selectedTrialClaimant: '',
    //     selectedTrialPointOfLaw: '',
    //     selectedTrialVenue: e.target.value
    //   });
    //   setTimeout(() => {
    //     this.changeTrialsOptions('trials_venue');
    //   }, 1000);
    // },
    // onTrialsCaseNoChange(e) {
    //   this.updateTrialFilter({
    //     ...this.trialFilters,
    //     selectedTrialClaimant: '',
    //     selectedTrialPointOfLaw: '',
    //     selectedTrialCaseNo: e.target.value
    //   });
    //   setTimeout(() => {
    //     this.changeTrialsOptions('trials_caseno');
    //   }, 1000);
    // },
    // onTrialsPointOfLawChange(e) {
    //   this.updateTrialFilter({
    //     ...this.trialFilters,
    //     selectedTrialClaimant: '',
    //     selectedTrialPointOfLaw: e.target.value
    //   });
    //   setTimeout(() => {
    //     this.changeTrialsOptions('trials_point_of_law');
    //   }, 1000);
    // },
    // onTrialsClaimantChange(e) {
    //   this.updateTrialFilter({ ...this.trialFilters, selectedTrialClaimant: e.target.value });
    // },
    // trialDatePickerformat(dates) {
    //   return (dates || []).filter(d => d).map(date => {
    //     const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    //     const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    //     const month = months[date.getMonth()];
    //     const year = date.getFullYear();
    //     return `${day} ${month} ${year}`;
    //   }).join(" to ");
    // },
    resetTabFilters() {
      if (this.isResetting) return null;
      this.isResetting = true;
      if (this.activeTab === 'cases') {
        eventBus.emit('on-reset-filter');
        this.searchQuery = '';
        this.searchCompanyQuery = '';
        this.casesTabFilters.companies = [];
        try {
          if (this.industriesRef) this.industriesRef.setChoiceByValue('');
          if (this.subIndustryRef) this.subIndustryRef.setChoiceByValue('');
          // if (this.companyRef) this.companyRef.setChoiceByValue('');
          if (this.citiesRef) this.citiesRef.setChoiceByValue('');
          if (this.resultRef) this.resultRef.setChoiceByValue('');
          if (this.pointOfLawRef) this.pointOfLawRef.setChoiceByValue('');
          if (this.judgeRef) this.judgeRef.setChoiceByValue('');
          if (this.caseTypeRef) this.caseTypeRef.setChoiceByValue('');
        } catch (error) {
          if (process.env.NODE_ENV === "development") console.log(error);
        }
        this.updateCasesFilters({
          selectedCity: "",
          selectedJudge: "",
          selectedCasesDate: [],
          selectedCompany: "",
          selectedIndustry: "",
          selectedResult: "",
          selectedPointOfLaw: "",
          selectedSubIndustry: "",
          selectedCaseType: "",
        });
        this.getAllCasesTabFilters(false);
      } else if (this.activeTab === 'case_details') {
        this.searchCaseQuery = '';
        this.caseDetailsFilters = [];
        this.updateCaseDetailFilters({ selectedCase: '' });
        this.isResetting = false;
      } /* else if (this.activeTab === 'company') {
        setTimeout(() => {
          const companyData = this.companies[0] || {};
          if (companyData.id) {
            this.updateCompanyFilters(({ selectedCompany: companyData.id }));
            if (this.company_companyRef) {
              this.company_companyRef.setChoiceByValue(companyData.id);
            }
          }
        }, 1000);
      } else if (this.activeTab === 'industry') {
        this.updateIndustryFilters({
          selectedYear: "",
          selectedIndustry: "",
          selectedSubIndustry: "",
          selectedCaseType: "",
        });
        setTimeout(() => {
          if (this.industry_yearsRef) {
            const yearChoices = orderBy(
              uniqBy(
                this.cases.filter(c => c.caseDate && moment(c.caseDate, 'YYYY-MM-DD HH:mm').isValid())
                  .map(cs => ({
                    label: moment(cs.caseDate, 'YYYY-MM-DD HH:mm').format("YYYY"),
                    value: moment(cs.caseDate, 'YYYY-MM-DD HH:mm').format("YYYY"),
                  })), 'value'),
              'value',
              'desc'
            );
            yearChoices.unshift({ label: "Select Year", value: "" });
            this.industry_yearsRef.setChoices(yearChoices, "value", "label", true);
            this.industry_yearsRef.setChoiceByValue("");
          }
          if (this.industry_industriesRef) {
            const industriesData = this.getIndustry_Industries().flatMap(ind => ind.industryData).filter(f => !!f?.GroupDesc);
            const indChoices = sortBy(uniqBy(industriesData.map(cs => ({
              label: cs.GroupDesc,
              value: cs.GroupDesc,
            })), 'value'), 'value');
            indChoices.unshift({ label: "Select Industries", value: "" });
            this.industry_industriesRef.setChoices(indChoices, "value", "label", true);
            this.industry_industriesRef.setChoiceByValue("");
          }
          if (this.industry_sub_industriesRef) {
            const subIndustriesData = this.getIndustry_SubIndustries().flatMap(ind => ind.industryData).filter(f => !!f?.CodeDesc);
            const subIndChoices = sortBy(uniqBy(subIndustriesData.map(cs => ({
              label: cs.CodeDesc,
              value: cs.CodeDesc,
            })), 'value'), 'value');
            subIndChoices.unshift({ label: "Select Sub-Industries", value: "" })
            this.industry_sub_industriesRef.setChoices(subIndChoices, "value", "label", true);
            this.industry_sub_industriesRef.setChoiceByValue("");
          }
          if (this.industry_caseTypeRef) {
            const caseTypeChoices = sortBy(uniqBy(this.getIndustry_CaseTypes().map(cs => ({
              label: cs.caseType,
              value: cs.caseType,
            })), 'value'), 'value');
            caseTypeChoices.unshift({ label: "Select Case Type", value: "" });
            this.industry_caseTypeRef.setChoices(caseTypeChoices, "value", "label", true);
            this.industry_caseTypeRef.setChoiceByValue("");
          }
        }, 1000);
      } else if (this.activeTab === 'legislation') {
        this.updateLNRFilters({ selectedTribunalType: '' });
        setTimeout(() => {
          if (this.tribunalTypeRef) {
            const tribunalTypeChoices = sortBy(uniqBy(this.cases.map(i => ({
              label: i.caseType,
              value: i.caseType,
            })), 'value'), 'value');
            tribunalTypeChoices.unshift({ label: "All", value: "" });
            this.tribunalTypeRef.setChoices(tribunalTypeChoices, "value", "label", true);
            this.tribunalTypeRef.setChoiceByValue("");
          }
          if (this.lawTypeRef) {
            const lawTypeChoices = sortBy(uniqBy(this.lawAndRegs.filter(l => (l?.lawType || '').trim()).map(i => ({
              label: i.lawType,
              value: i.lawType,
            })), 'value'), 'value');
            lawTypeChoices.unshift({ label: "All", value: "" });
            this.lawTypeRef.setChoices(lawTypeChoices, "value", "label", true);
            this.lawTypeRef.setChoiceByValue("");
          }
        }, 1000);
      } */
      // else if (this.activeTab === 'trials') {
      //   this.updateTrialFilter({
      //     selectedTrialDate: [],
      //     selectedTrialType: '',
      //     selectedTrialVenue: '',
      //     selectedTrialCaseNo: '',
      //     selectedTrialClaimant: '',
      //     selectedTrialPointOfLaw: '',
      //     selectedTrialHearingTime: '',
      //   });
      //   setTimeout(() => {
      //     if (this.trialsTypeRef) {
      //       const trialsTypeChoices = sortBy(uniqBy(
      //         this.getTrialsTypeWise().filter(d => d.trial_type).map(td => ({ label: td.trial_type, value: td.trial_type })),
      //         'value',
      //       ), 'value');
      //       trialsTypeChoices.unshift({ label: "All", value: "" });
      //       this.trialsTypeRef.setChoices(trialsTypeChoices, "value", "label", true);
      //       this.trialsTypeRef.setChoiceByValue('');
      //     }
      //     if (this.trialsHearingTimeRef) {
      //       const trialsHearingTimeChoices = sortBy(uniqBy(
      //         this.getTrialsHearingTimeWise().filter(d => d['Jurisdiction(s)'] && d.Time)
      //           .map(td => ({ label: td.Time, value: td.Time })),
      //         'value',
      //       ), 'value');
      //       trialsHearingTimeChoices.unshift({ label: "All", value: "" });
      //       this.trialsHearingTimeRef.setChoices(trialsHearingTimeChoices, "value", "label", true);
      //       this.trialsHearingTimeRef.setChoiceByValue('');
      //     }
      //     if (this.trialsVenueRef) {
      //       const trialsVenueChoices = sortBy(uniqBy(
      //         this.getTrialsVenueWise().filter(d => d.Venue).map(td => ({ label: td.Venue, value: td.Venue })),
      //         'value',
      //       ), 'value');
      //       trialsVenueChoices.unshift({ label: "All", value: "" });
      //       this.trialsVenueRef.setChoices(trialsVenueChoices, "value", "label", true);
      //       this.trialsVenueRef.setChoiceByValue('');
      //     }
      //     if (this.trialsCaseNoRef) {
      //       const trialsCaseNoChoices = sortBy(uniqBy(
      //         this.getTrialsCaseNoWise().filter(d => d['Case No.']).map(td => ({ label: td['Case No.'], value: td['Case No.'] })),
      //         'value',
      //       ), 'value');
      //       trialsCaseNoChoices.unshift({ label: "All", value: "" });
      //       this.trialsCaseNoRef.setChoices(trialsCaseNoChoices, "value", "label", true);
      //       this.trialsCaseNoRef.setChoiceByValue('');
      //     }
      //     if (this.trialsPointOfLawRef) {
      //       const trialsPointOfLawChoices = uniqBy(
      //         this.getTrialsPointOfLawWise().flatMap(trlDt => trlDt['Jurisdiction(s)'].split(','))
      //           .map(code => ({ label: code.trim(), value: code.trim() })),
      //         'value',
      //       );
      //       trialsPointOfLawChoices.unshift({ label: "All", value: "" });
      //       this.trialsPointOfLawRef.setChoices(trialsPointOfLawChoices, "value", "label", true);
      //       this.trialsPointOfLawRef.setChoiceByValue('');
      //     }
      //     if (this.trialsClaimantRef) {
      //       const trialsClaimantChoices = sortBy(uniqBy(
      //         this.getTrialsClaimantWise().map(clmnt => ({
      //           label: (clmnt.Claimant || '').replace(/\n/g, ', '),
      //           value: (clmnt.Claimant || '').split("\n")[0]
      //         })),
      //         'value',
      //       ), 'value');
      //       trialsClaimantChoices.unshift({ label: "All", value: "" });
      //       this.trialsClaimantRef.setChoices(trialsClaimantChoices, "value", "label", true);
      //       this.trialsClaimantRef.setChoiceByValue('');
      //     }
      //   }, 1000);
      // }
    },
    handleCompanySearch(query) {
      if (!this.isAuthenticated) {
        return null;
      }
      if (this.searchCompanyTimeout) clearTimeout(this.searchCompanyTimeout);
      this.searchCompanyTimeout = setTimeout(async () => {
        if (!query) {
          this.casesTabFilters.companies = [];
          return null;
        }
        try {
          const filterData = {
            query: { bool: {} },
            _source: ["companies.name", "companies.id"],
            highlight: {
              order: "score",
              pre_tags: ["<strong class=\"bg-highlight\">"],
              post_tags: ["</strong>"],
              fields: { "companies.name": {} }
            }
          }
          const queryShould = this.getCasesQueryFilter(true);
          if (this.searchCompanyQuery) {
            const searchQueryFilter = [];
            searchQueryFilter.push({ match_phrase_prefix: { 'companies.name': { query, boost: 2 } } });
            searchQueryFilter.push({ match: { 'companies.name': { query, boost: 1 } } });
            filterData.min_score = 1;
            filterData.query.bool.minimum_should_match = 1;
            filterData.query.bool.should = searchQueryFilter;
          }

          if (queryShould.length) {
            filterData.query.bool.filter = queryShould;
          }
          const response = await elasticSearchApiInstance.post('/_search', filterData);
          const { hits } = response?.data?.hits || {};
          const highlightedData = uniqBy(hits.flatMap(h =>
            (h?.highlight?.['companies.name'] ?? []).map((highlightedTxt, ind) => ({
              highlightedTxt,
              ...(h?._source?.companies?.[ind] ?? {})
            }))
          ), 'id');
          this.casesTabFilters.companies = highlightedData;
        } catch (error) {
          const errorMessage = handleError(error);
          if (errorMessage) {
            this.$swal({
              title: 'Error!',
              text: errorMessage,
              icon: 'error',
              confirmButtonText: 'Okay',
              customClass: {
                confirmButton: "btn bg-success",
              },
              buttonsStyling: false,
            });
          }
          this.casesTabFilters.companies = [];
        }
      }, 400);
    },
    handleCaseSearch(query) {
      if (!this.isAuthenticated) {
        return null;
      }
      if (this.searchCaseTimeout) clearTimeout(this.searchCaseTimeout);
      this.searchCaseTimeout = setTimeout(async () => {
        if (!query) {
          if (this.caseDetailsFilters) {
            this.caseDetailsFilters = [];
          }
          return null;
        }
        try {
          const filterData = {
            min_score: 1,
            _source: ["caseName", "decisionID"],
            highlight: {
              order: "score",
              pre_tags: ["<strong class=\"bg-highlight\">"],
              post_tags: ["</strong>"],
              fields: { "caseName": {} }
            },
            query: {
              bool: {
                should: [
                  { match_phrase_prefix: { caseName: { query, boost: 2 } } },
                  { match: { caseName: { query, boost: 1 } } }
                ],
                minimum_should_match: 1,
              }
            },
          }
          const response = await elasticSearchApiInstance.post('/_search', filterData);
          const { hits } = response?.data?.hits || {};
          const highlightedData = hits.flatMap(h =>
            (h?.highlight?.caseName ?? []).map((highlightedTxt) => ({
              highlightedTxt,
              ...(h?._source ?? {})
            }))
          );
          this.caseDetailsFilters = highlightedData;
        } catch (error) {
          const errorMessage = handleError(error);
          if (errorMessage) {
            this.$swal({
              title: 'Error!',
              text: errorMessage,
              icon: 'error',
              confirmButtonText: 'Okay',
              customClass: {
                confirmButton: "btn bg-success",
              },
              buttonsStyling: false,
            });
          }
          this.caseDetailsFilters = [];
        }
      }, 400);
    },
    onSearchInputFocus() {
      this.showSearchSuggestions = true;
    },
    onSearchInputBlur() {
      setTimeout(() => {
        this.showSearchSuggestions = false;
      }, 500);
    }
  },
};
</script>

<style>
select {
  border-radius: 0px;
  color: #fff;
  font-size: 13px;
  padding: 0.3rem 0.5rem;
}

.choices[data-type*=select-one]::after {
  color: #fff !important;
}

.custom_menu_list .choices__list--dropdown .choices__list {
  max-height: 180px;
}
</style>
