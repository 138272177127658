const state = {
  cases: [],
  similarCases: [],
  cities: [],
  companies: [],
  industries: [],
  trialsData: [],
  lawAndRegs: [],
  activeTab: "",
  casesFilters: {
    selectedCity: "",
    selectedJudge: "",
    selectedResult: "",
    selectedCompany: "",
    selectedIndustry: "",
    selectedCasesDate: [],
    selectedPointOfLaw: "",
    selectedSubIndustry: "",
    selectedCaseType: "",
  },
  caseDetailFilters: {
    selectedCase: "",
  },
  companyFilters: {
    selectedCompany: "",
  },
  industryFilters: {
    selectedYear: "",
    selectedIndustry: "",
    selectedSubIndustry: "",
    selectedCaseType: "",
  },
  trialFilters: {
    selectedTrialType: '',
    selectedTrialVenue: '',
    selectedTrialCaseNo: '',
    selectedTrialClaimant: '',
    selectedTrialDate: [],
    selectedTrialHearingTime: '',
    selectedTrialPointOfLaw: '',
  },
  lnrFilters: {
    selectedTribunalType: "",
    selectedLawType: "",
  },
  casesFooterData: {},
  companyFooterData: {},
  industryFooterData: {},
  trialsFooterData: {},
  lawAndRegsFooterData: {},
};

const getters = {
  cases: (state) => state.cases,
  similarCases: (state) => state.similarCases,
  casesFilters: (state) => state.casesFilters,
  caseDetailFilters: (state) => state.caseDetailFilters,
  companies: (state) => state.companies,
  companyFilters: (state) => state.companyFilters,
  industryFilters: (state) => state.industryFilters,
  industries: (state) => state.industries,
  activeTab: (state) => state.activeTab,
  cities: (state) => state.cities,
  trialsData: (state) => state.trialsData,
  trialFilters: (state) => state.trialFilters,
  casesFooterData: (state) => state.casesFooterData,
  companyFooterData: (state) => state.companyFooterData,
  industryFooterData: (state) => state.industryFooterData,
  trialsFooterData: (state) => state.trialsFooterData,
  lawAndRegs: (state) => state.lawAndRegs,
  lawAndRegsFooterData: (state) => state.lawAndRegsFooterData,
  lnrFilters: (state) => state.lnrFilters,
};

const mutations = {
  updateCases(state, payload) {
    state.cases = payload;
  },
  updateSimilarCases(state, payload) {
    state.similarCases = payload;
  },
  updateCasesFilters(state, payload) {
    state.casesFilters = payload;
  },
  updateCaseDetailFilters(state, payload) {
    state.caseDetailFilters = payload;
  },
  updateCompany(state, payload) {
    state.companies = payload;
  },
  updateCompanyFilters(state, payload) {
    state.companyFilters = payload;
  },
  updateIndustryFilters(state, payload) {
    state.industryFilters = payload;
  },
  updateIndustry(state, payload) {
    state.industries = payload;
  },
  updateCities(state, payload) {
    state.cities = payload;
  },
  updateActiveTab(state, payload) {
    state.activeTab = payload;
  },
  updateTrialData(state, payload) {
    state.trialsData = payload;
  },
  updateTrialFilter(state, payload) {
    state.trialFilters = payload;
  },
  updateCasesFooterData(state, payload) {
    state.casesFooterData = payload;
  },
  updateCompanyFooterData(state, payload) {
    state.companyFooterData = payload;
  },
  updateIndustryFooterData(state, payload) {
    state.industryFooterData = payload;
  },
  updateTrialsFooterData(state, payload) {
    state.trialsFooterData = payload;
  },
  updateLawAndRegs(state, payload) {
    state.lawAndRegs = payload;
  },
  updateLawAndRegsFooterData(state, payload) {
    state.lawAndRegsFooterData = payload;
  },
  updateLNRFilters(state, payload) {
    state.lnrFilters = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};