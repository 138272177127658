import axios from "axios";

const elasticSearchApiInstance = axios.create({
  baseURL: `${process.env.VUE_APP_ELASTICSEARCH_ENDPOINT}${process.env.VUE_APP_ELASTICSEARCH_INDEX}`,
  auth: {
    username: process.env.VUE_APP_ELASTICSEARCH_USERNAME,
    password: process.env.VUE_APP_ELASTICSEARCH_PASSWORD,
  }
});

export const dataSize = 10000;

export const casesTabFilterAggs = (ind, subInd) => ({
  industries: {
    terms: {
      field: 'industries.GroupShort.keyword',
      size: ind || subInd ? 1 : dataSize * 10,
      include: ind ? [ind] : [],
    },
    aggs: {
      data_bucket_sort: {
        bucket_sort: {
          sort: [{ _count: { order: "desc" } }]
        }
      }
    }
  },
  subIndustries: {
    terms: {
      field: 'industries.CodeDesc.keyword',
      size: subInd ? 1 : dataSize * 10,
      include: subInd ? [subInd] : [],
    },
    aggs: {
      data_bucket_sort: {
        bucket_sort: {
          sort: [{ _count: { order: "desc" } }]
        }
      }
    }
  },
  // companies: {
  //   terms: { field: 'companies.name.keyword', size: dataSize * 10 },
  // },
  cities: {
    terms: {
      size: dataSize,
      field: 'city.keyword',
    }
  },
  results: {
    terms: {
      size: dataSize,
      field: 'result.keyword',
    },
  },
  pointOfLaws: {
    terms: {
      size: dataSize,
      field: 'category.keyword',
    },
  },
  judges: {
    terms: {
      size: dataSize,
      field: 'heardBefore.keyword',
    },
  },
  caseTypes: {
    terms: {
      size: dataSize,
      field: 'caseType.keyword',
    },
  },
});

export const getOnlyIndustries = {
  industries: {
    terms: {
      field: 'industries.GroupShort.keyword',
      size: dataSize * 10,
    },
    aggs: {
      data_bucket_sort: {
        bucket_sort: {
          sort: [{ _key: { order: "asc" } }]
        }
      }
    }
  }
};

export const casesTabChartFilterAggs = {
  casesByTimeline: {
    date_histogram: {
      field: "caseDate",
      calendar_interval: "year"
    }
  },
  caseByTypes: {
    terms: {
      size: dataSize,
      field: "caseType.keyword",
    }
  },
  caseByResults: {
    terms: {
      size: dataSize,
      field: "result.keyword",
    }
  }
};

export const predictionsTabFilterAggs = {
  industries: {
    terms: { field: 'industries.GroupShort.keyword', size: dataSize * 10 },
  },
  subIndustries: {
    terms: { field: 'industries.CodeDesc.keyword', size: dataSize * 10 },
  },
  pointOfLaws: {
    terms: { field: 'category.keyword', size: dataSize },
  },
};

export const getSimilarCaseFilter = (index, docId) => ({
  size: 5,
  query: {
    more_like_this: {
      fields: [
        "caseNo",
        "caseName",
        "caseType",
        "category",
        "subcategory",
        "source",
        "claimant",
        "respondent",
        "country",
        "decision",
        "result",
        "city"
      ],
      like: [{ _index: index, _id: docId }],
      min_term_freq: 1,
      max_query_terms: 25,
      min_doc_freq: 1,
      max_doc_freq: 1000,
      min_word_length: 2
    }
  },
  _source: ["decisionID", "caseName", "caseDate"]
});

export default elasticSearchApiInstance;