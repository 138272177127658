<template>
  <template v-if="fetching">
    <div class="landing-bg h-100 position-fixed w-100 d-flex align-items-center justify-content-center">
      <ArgonLoader />
    </div>
  </template>

  <template v-else>
    <div v-show="layout === 'landing'" class="landing-bg h-100 bg-gradient-primary position-fixed w-100">
    </div>
    <sidenav v-if="!fetching && showSidenav" />
    <main id="main-content-view" class="main-content position-relative max-height-vh-100 h-100"
      style="overflow-y: auto;">
      <navbar v-if="showNavbar" />
      <router-view />
      <app-footer v-show="showFooter" />
      <!-- <configurator :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']" /> -->
    </main>
  </template>
</template>

<script>
import { toRaw } from "vue";
import { mapGetters } from "vuex";

import Sidenav from "@/examples/Sidenav";
import AppFooter from "@/examples/Footer.vue";
import Navbar from "@/examples/Navbars/Navbar2.vue";
import ArgonLoader from "@/components/ArgonLoader.vue";
// import Configurator from "@/examples/Configurator.vue";

import { dashboardRoutes } from "@/router";

export default {
  name: "App",
  components: {
    Sidenav,
    // Configurator,
    Navbar,
    AppFooter,
    ArgonLoader
  },
  data: function () {
    return {
      fetching: true,
    }
  },
  computed: {
    ...mapGetters("theme", [
      "layout",
      "showSidenav",
      "showNavbar",
      "showFooter",
      // "showConfig",
      // "hideConfigButton"
    ])
  },
  created() {
    setTimeout(() => {
      const isAuthenticated = toRaw(this?.$auth0?.isAuthenticated)?.value || process.env.NODE_ENV == 'development';
      if (isAuthenticated) {
        if (!dashboardRoutes.some(r => r.path == this.$route.fullPath)) {
          this.$router.replace("/dashboards");
        }
      } else {
        this.$router.replace("/signin");
      }
      setTimeout(() => {
        this.fetching = false;
      }, 1000);
    }, 3000);
    // let value = prompt("Please enter passworrd:", "");
    // if (value == null || value == "") {
    //   window.location.href = 'http://www.google.com';
    // } else if (value == 'kort@001') {
    //   return true;
    // } else {
    //   window.location.href = 'http://www.google.com';
    // }
  },
};
</script>
