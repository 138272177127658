<template>
  <nav id="navbarBlur" :class="navbarClasses()" v-bind="$attrs" data-scroll="true">
    <div class="px-3 py-1 w-100">
      <div id="navbar" class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4 mb-md-2"
        :class="isRTL ? 'px-0' : 'me-sm-4'">
        <ul class="navbar-nav justify-content-end ms-md-auto">
          <li class="nav-item d-none pe-md-3 d-sm-flex align-items-center">
            <div class="sidenav-toggler sidenav-toggler-inner d-xl-none d-block" :class="isRTL ? 'me-3' : ''">
              <a id="iconNavbarSidenav" href="#" class="p-0 nav-link text-body" @click="navbarMinimize">
                <div class="sidenav-toggler-inner">
                  <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-dark'" />
                  <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-dark'" />
                  <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-dark'" />
                </div>
              </a>
            </div>
          </li>
          <li v-if="isAuthenticated" class="nav-item dropdown d-flex align-items-center me-2">
            <a id="dropdownMenuButton" href="#" class="px-0 nav-link font-weight-bold" :class="`${isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'}${showMenu ? ' show' : ''}`
              " data-bs-toggle="dropdown" aria-expanded="false" @click="showMenu = !showMenu">
              <i class="fa fa-user text-primary" :class="isRTL ? 'ms-sm-2' : 'me-sm-1'"></i>
              <span class="d-sm-inline d-none text-primary">
                {{ user?.name || "" }}
              </span>
            </a>
            <ul class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4" :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton">
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;" @click.prevent="onLogoutPress">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <i class="fas fa-sign-out-alt me-3" />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Logout</span>
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item d-flex pe-md-3 d-sm-none align-items-center">
            <div class="sidenav-toggler sidenav-toggler-inner d-xl-none d-block" :class="isRTL ? 'me-3' : ''">
              <a id="iconNavbarSidenav" href="#" class="p-0 nav-link text-body" @click="navbarMinimize">
                <div class="sidenav-toggler-inner">
                  <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-dark'" />
                  <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-dark'" />
                  <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-dark'" />
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div class="row align-items-center">
        <div class="col-12 px-0">
          <div class="nav-wrapper position-relative end-0">
            <ul class="nav nav-pills nav-fill p-1 tab-list" role="tablist" style="flex-wrap: nowrap !important;">
              <li class="nav-item">
                <a id="cases_tab" href="#cases" class="nav-link mb-0 px-0 py-1 active tab-nav text-nowrap tab-text"
                  style="outline: none;" role="tab" aria-controls="cases" aria-selected="true" data-bs-toggle="tab"
                  @click="setActiveTab('cases')">Case Explorer</a>
              </li>
              <li class="nav-item">
                <a id="case_details_tab" href="#case_details"
                  class="nav-link mb-0 px-0 py-1 active tab-nav text-nowrap tab-text case-details-tab"
                  style="outline: none;" role="tab" aria-controls="case_details" aria-selected="true"
                  data-bs-toggle="tab" @click="setActiveTab('case_details')">Case Inspector</a>
              </li>
              <!-- <li class="nav-item">
              <a id="case_connections_tab" href="#case_connections" class="nav-link mb-0 px-0 py-1 active tab-nav text-nowrap tab-text"
                style="outline: none;" role="tab" aria-controls="case_connections" aria-selected="true"
                data-bs-toggle="tab" @click="setActiveTab('case_connections')">Case Connections</a>
            </li> -->
              <!-- <li class="nav-item">
                <a id="legislation_tab" class="nav-link mb-0 px-0 py-1 tab-nav text-nowrap tab-text legislation-tab" data-bs-toggle="tab"
                  style="outline: none;" href="#legislation" aria-controls="legislation" role="tab"
                  aria-selected="false" @click="setActiveTab('legislation')">Legislation</a>
              </li>
              <li class="nav-item">
                <a id="company_tab" class="nav-link mb-0 px-0 py-1 tab-nav text-nowrap tab-text company-tab" style="outline: none;"
                  data-bs-toggle="tab" href="#company" aria-controls="company" role="tab" aria-selected="false"
                  @click="setActiveTab('company')">Company</a>
              </li>
              <li class="nav-item">
                <a id="industry_tab" class="nav-link mb-0 px-0 py-1 tab-nav text-nowrap tab-text industry-tab" style="outline: none;"
                  data-bs-toggle="tab" href="#industry" aria-controls="industry" role="tab" aria-selected="false"
                  @click="setActiveTab('industry')">Industry</a>
              </li> -->
              <li class="nav-item">
                <a id="prediction_tab" class="nav-link mb-0 px-0 py-1 tab-nav text-nowrap tab-text prediction-tab"
                  data-bs-toggle="tab" style="outline: none;" href="#prediction" aria-controls="prediction" role="tab"
                  aria-selected="false" @click="setActiveTab('prediction')">Case Predictor</a>
              </li>
              <!-- <li class="nav-item">
              <a id="trials_tab" class="nav-link mb-0 px-0 py-1 tab-nav text-nowrap tab-text trials-tab" data-bs-toggle="tab"
                style="outline: none;" href="#trials" aria-controls="trials" role="tab" aria-selected="false"
                @click="setActiveTab('trials')">Trials</a>
            </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { toRaw } from "vue";
import { mapMutations, mapGetters } from "vuex";
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "Navbar",
  data() {
    return {
      showMenu: false,
      user: toRaw(this?.$auth0?.user)?.value || {},
    };
  },
  computed: {
    ...mapGetters("theme", ["isRTL", "isNavFixed", "darkMode"]),
    isAuthenticated: function () {
      return toRaw(this?.$auth0?.isAuthenticated)?.value || process.env.NODE_ENV == 'development';
    },
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
  },
  beforeUpdate() {
    this.toggleNavigationOnMobile();
  },
  mounted() {
    setNavPills();
  },
  methods: {
    ...mapMutations("theme", ["navbarMinimize"]),
    ...mapMutations("dashboard", ["updateActiveTab"]),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }
    },
    onLogoutPress() {
      this.showMenu = false;
      this.$auth0.logout();
    },
    navbarClasses() {
      return `${!this.isNavFixed
        ? 'navbar navbar-main navbar-expand-lg p-0 mx-4 border-radius-xl shadow-none'
        : `navbar navbar-main navbar-expand-lg p-0 mx-4 border-radius-xl shadow-none position-sticky ${this.darkMode ? 'bg-default' : 'bg-white'
        } left-auto top-0 mt-3 z-index-sticky`
        } ${this.isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`
    },
    setActiveTab(tab) {
      this.updateActiveTab(tab);
      setTimeout(() => {
        document.querySelector('main').scrollTo({ top: 0, left: 0, behavior: 'auto' });
      });
    }
  }
};
</script>
<style scoped>
.tab-text {
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
}

@media only screen and (max-width : 767px) {
  .tab-text {
    font-size: 0.9rem !important;
    font-weight: 600 !important;
    line-height: 1.175 !important;
  }
}
</style>