<template>
  <div class="container-fluid pb-0 mt-3">
    <div v-if="isAuthenticated" id="v-pills-tabContent" class="tab-content">
      <div id="cases" :class="['tab-pane fade', activeTab == 'cases' && 'show active']" role="tabpanel"
        aria-labelledby="cases">
        <Cases />
      </div>
      <div id="case_details" :class="['tab-pane fade', activeTab == 'case_details' && 'show active']" role="tabpanel"
        aria-labelledby="case_details">
        <CaseDetails />
      </div>
      <!-- <div id="case_connections" :class="['tab-pane fade', activeTab == 'case_connections' && 'show active']"
        role="tabpanel" aria-labelledby="case_connections">
        <CaseConnections />
      </div> -->
      <!-- <div id="company" :class="['tab-pane fade', activeTab == 'company' && 'show active']" role="tabpanel"
        aria-labelledby="company">
        <Company />
      </div> -->
      <!-- <div id="industry" :class="['tab-pane fade', activeTab == 'industry' && 'show active']" role="tabpanel"
        aria-labelledby="industry">
        <Industry />
      </div> -->
      <div id="prediction" :class="['tab-pane fade position-relative', activeTab == 'prediction' && 'show active']"
        role="tabpanel" aria-labelledby="prediction">
        <Prediction />
      </div>
      <!-- <div id="trials" :class="['tab-pane fade position-relative', activeTab == 'trials' && 'show active']"
        role="tabpanel" aria-labelledby="trials">
        <Trials />
      </div> -->
      <!-- <div id="legislation" :class="['tab-pane fade position-relative', activeTab == 'legislation' && 'show active']"
        role="tabpanel" aria-labelledby="legislation">
        <LawsAndRegulations />
      </div> -->
    </div>
  </div>
</template>

<script>
import { toRaw } from 'vue';
import { mapMutations, mapGetters } from "vuex";

import Cases from "./Tabs/Cases.vue";
// import Trials from "./Tabs/Trials.vue";
// import Company from "./Tabs/Company.vue";
// import Industry from "./Tabs/Industry.vue";
import Prediction from "./Tabs/Prediction.vue";
import CaseDetails from "./Tabs/CaseDetails.vue";
// import CaseConnections from "./Tabs/CaseConnections.vue";
// import LawsAndRegulations from "./Tabs/LawsAndRegulations.vue";

import { updateMovingTab } from '@/assets/js/nav-pills';

export default {
  name: "MyDashboard",
  components: {
    Cases,
    // Trials,
    // Company,
    // Industry,
    Prediction,
    CaseDetails,
    // CaseConnections,
    // LawsAndRegulations,
  },
  data() {
    return {
      headerHeight: 0,
      isSidebarNarrow: false,
    }
  },
  computed: {
    ...mapGetters("dashboard", ["activeTab"]),
    isAuthenticated: function () {
      return toRaw(this?.$auth0?.isAuthenticated)?.value || process.env.NODE_ENV == 'development';
    },
  },
  watch: {
    activeTab: function (newTab) {
      if (window.screen.width > 1024) {
        if (!this.isSidebarNarrow && newTab === 'prediction') {
          this.setSidebarNarrow();
          updateMovingTab();
        } else if (this.isSidebarNarrow && newTab !== 'prediction') {
          this.setSidebarRegular();
          updateMovingTab();
        }
      }
    },
  },
  unmounted() {
    this.updateActiveTab('cases');
  },
  mounted() {
    const elem = document.getElementById("navbarBlur");
    if (elem) {
      const rect = elem.getBoundingClientRect();
      this.headerHeight = rect.height;
    }
  },
  created() {
    this.updateActiveTab('cases');
  },
  methods: {
    ...mapMutations('dashboard', ['updateActiveTab']),
    getCasesData(data = [], key = '', value = '') {
      return data.length && key && value ? data.find(d => d[key] && value && String(d[key]).toLowerCase() == String(value).toLowerCase()) : {};
    },
    setActiveTab(tab) {
      this.updateActiveTab(tab);
      setTimeout(() => {
        document.querySelector('main').scrollTo({ top: 0, left: 0, behavior: 'auto' });
      });
    },
    setSidebarNarrow() {
      this.isSidebarNarrow = true;
      const asideElement = document.getElementById('sidenav-main');
      if (asideElement) {
        asideElement.setAttribute('style', 'max-width:12rem !important');
      }
      const mainContentView = document.getElementById('main-content-view');
      if (mainContentView) {
        mainContentView.setAttribute('style', 'margin-left:13.5rem !important');
      }
      const tabElement = document.getElementById(`${this.activeTab}_tab`);
      if (tabElement) {
        setTimeout(() => {
          tabElement.click();
          tabElement.classList.add('active');
        }, 200);
      }
    },
    setSidebarRegular() {
      this.isSidebarNarrow = false;
      const asideElement = document.getElementById('sidenav-main');
      if (asideElement) {
        asideElement.setAttribute('style', 'max-width:18rem !important');
      }
      const mainContentView = document.getElementById('main-content-view');
      if (mainContentView) {
        mainContentView.setAttribute('style', 'margin-left:19.5rem !important');
      }
      const tabElement = document.getElementById(`${this.activeTab}_tab`);
      if (tabElement) {
        setTimeout(() => {
          tabElement.click();
          tabElement.classList.add('active');
        }, 200);
      }
    },
  },
}
</script>
