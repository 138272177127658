<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="caseDetailsState.fetching" class="empty-view">
    <ArgonLoader />
  </div>
  <div v-else-if="isEmpty(caseDetailsState.details)" class="empty-container">
    <div class="card w-100" style="padding: 100px 0;">
      <div class="card-body py-0">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h3 class="title-color">No case details found.</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="title-color mb-2">{{ capitalizeFirstLetter(caseDetailsState.details?.caseName || '-') }}</h5>
    <div class="row mb-2 mb-md-0">
      <div class="col-12" :class="{ 'col-lg-8': !isEmpty(caseDetailsState.details?.caseHistory) }">
        <div class="mb-3 card mt-lg-0">
          <div class="card-header pb-0">
            <div class="row">
              <div class="col-12">
                <h5 class="mb-0 text-primary">
                  Case Information
                </h5>
              </div>
            </div>
          </div>
          <div class="pb-0 card-body">
            <ul class="mx-auto list-unstyled">
              <li class="row">
                <div class="col-4 pe-0">
                  <p class="mb-0">Respondent:</p>
                </div>
                <div class="col-8 d-flex justify-content-end">
                  <!-- <span class="text-sm lh-lg ms-auto text-end text-capitalize">
                    <template
                      v-if="caseDetailsState?.details?.respondent && caseDetailsState?.details?.respondent.length > 0">
                      <template v-for="(respondent, ind) of caseDetailsState?.details?.respondent" :key="respondent">
                        <span
                          v-if="(caseDetailsState?.details?.respondantData || []).some(c => String(c?.respondent || '').toLowerCase() == String(respondent || '').toLowerCase())"
                          class="case_title"
                          @click.prevent="onRespondantClick((caseDetailsState?.details?.respondantData || []).find(c => String(c?.respondent || '').toLowerCase() == String(respondent || '').toLowerCase()))">
                          {{ `${capitalizeFirstLetter(respondent)}${ind == caseDetailsState?.details?.respondent.length
    - 1 ? '' : ', '}` }}
                        </span>
                        <span v-else>
                          {{ `${capitalizeFirstLetter(respondent)}${ind == caseDetailsState?.details?.respondent.length
    - 1 ? '' : ', '}` }}
                        </span>
                      </template>
</template>
<span v-else>-</span>
</span> -->
                  <span class="text-sm lh-lg ms-auto text-end text-capitalize">
                    {{ caseDetailsState?.details?.respondent &&
                      caseDetailsState?.details?.respondent.length > 0 ?
                      caseDetailsState?.details?.respondent.join(", ") : '-' }}
                  </span>
                </div>
              </li>
              <li>
                <hr class="horizontal dark" />
              </li>
              <li class="row">
                <div class="col-4 pe-0">
                  <p class="mb-0">Respondent representation:</p>
                </div>
                <div class="col-8 d-flex justify-content-end">
                  <span class="text-sm lh-lg ms-auto text-end text-capitalize">
                    {{ caseDetailsState?.details?.respondentRepresentation &&
                      caseDetailsState?.details?.respondentRepresentation.length > 0 ?
                      caseDetailsState?.details?.respondentRepresentation.join(", ") : '-' }}
                  </span>
                </div>
              </li>
              <li>
                <hr class="horizontal dark" />
              </li>
              <li class="row">
                <div class="col-4 pe-0">
                  <p class="mb-0">Claimant:</p>
                </div>
                <div class="col-8 d-flex justify-content-end">
                  <span class="text-sm lh-lg ms-auto text-end text-capitalize">
                    {{ (caseDetailsState?.details?.claimant || []).length ?
                      (caseDetailsState?.details?.claimant ||
                        []).join(", ") : '-' }}
                  </span>
                </div>
              </li>
              <li>
                <hr class="horizontal dark" />
              </li>
              <li class="row">
                <div class="col-4 pe-0">
                  <p class="mb-0">Claimant representation:</p>
                </div>
                <div class="col-8 d-flex justify-content-end">
                  <span class="text-sm lh-lg ms-auto text-end text-capitalize">
                    {{ (caseDetailsState?.details?.claimantRepresentation || []).length ?
                      (caseDetailsState?.details?.claimantRepresentation ||
                        []).join(", ") : '-' }}
                  </span>
                </div>
              </li>
              <li>
                <hr class="horizontal dark" />
              </li>
              <li class="row">
                <div class="col-4 pe-0">
                  <p class="mb-0">Place:</p>
                </div>
                <div class="col-8 d-flex justify-content-end">
                  <span class="text-sm lh-lg ms-auto text-end text-capitalize">
                    <!-- {{ (caseDetailsState?.details?.city || []).length ?
                      `${(caseDetailsState?.details?.city ||
                        []).map(c =>
                          `${c}${caseDetailsState?.details?.country ? `, ${caseDetailsState.details?.country}` :
                            ''}`).join('\n')}` : `${caseDetailsState?.details?.country ?? '-'}`
                    }} -->
                    {{ caseDetailsState?.details?.city ? `${caseDetailsState?.details?.city} ` : '' }}{{
                      caseDetailsState?.details?.country ?? "-" }}
                  </span>
                </div>
              </li>
              <li>
                <hr class="horizontal dark" />
              </li>
              <li class="row">
                <div class="col-4 pe-0">
                  <p class="mb-0">Judge:</p>
                </div>
                <div class="col-8 d-flex justify-content-end flex-wrap gap-2 ms-auto">
                  <span
                    v-if="caseDetailsState?.details?.heardBefore && caseDetailsState?.details?.heardBefore.length > 0"
                    class="text-sm lh-lg text-end" style="white-space: break-spaces !important;">
                    {{
                      (caseDetailsState.details?.heardBefore || [])
                        .map(j => `Employment Judge ${j}`).join(', ')
                    }}
                  </span>
                  <span v-else>-</span>
                </div>
              </li>
              <li>
                <hr class="horizontal dark" />
              </li>
              <!-- <li class="row">
                <div class="col-4 pe-0">
                  <p class="mb-0">Published on:</p>
                </div>
                <div class="col-8 d-flex justify-content-end">
                  <span class="text-sm lh-lg ms-auto text-end">
                    {{ (caseDetailsState?.details?.caseHistory || []).length > 0 ?
    getFormatedDate(caseDetailsState.details?.caseHistory[0].date) : moment().format('DD MMM YYYY') }}
                  </span>
                </div>
              </li>
              <li>
                <hr class="horizontal dark" />
              </li>
              <li class="row">
                <div class="col-4 pe-0">
                  <p class="mb-0">Last updated on:</p>
                </div>
                <div class="col-8 d-flex justify-content-end">
                  <span class="text-sm lh-lg ms-auto text-end">
                    {{ (caseDetailsState?.details?.caseHistory || []).length > 0 ?
    getFormatedDate(caseDetailsState.details?.caseHistory[caseDetailsState?.details?.caseHistory.length -
      1].date) :
    moment().format('DD MMM YYYY') }}
                  </span>
                </div>
              </li>
              <li>
                <hr class="horizontal dark" />
              </li> -->
              <li class="row">
                <div class="col-4 pe-0">
                  <p class="mb-0">Case type:</p>
                </div>
                <div class="col-8 d-flex justify-content-end">
                  <span class="badge badge-secondary badge-sm ms-auto text-end text-break"
                    style="line-height: inherit !important; white-space: break-spaces !important;">
                    {{ caseDetailsState.details?.caseType || '-' }}
                  </span>
                </div>
              </li>
              <li>
                <hr class="horizontal dark" />
              </li>
              <!-- <li v-if="caseDetailsState.details?.caseDate" class="row">
                <div class="col-4 pe-0">
                  <p class="mb-0">Date:</p>
                </div>
                <div class="col-8 d-flex justify-content-end">
                  <span class="text-sm lh-lg ms-auto text-end">
                    {{ getFormatedDate(caseDetailsState.details?.caseDate) }}
                  </span>
                </div>
              </li>
              <li v-if="caseDetailsState.details?.caseDate">
                <hr class="horizontal dark" />
              </li> -->
              <li class="row">
                <div class="col-4 pe-0">
                  <p class="mb-0">Jurisdiction codes:</p>
                </div>
                <div class="col-8 d-flex justify-content-end flex-wrap gap-2 ms-auto">
                  <template v-if="(caseDetailsState.details?.category || []).length > 0">
                    <span v-for="code of (caseDetailsState.details?.category || [])" :key="code"
                      class="badge badge-sm bg-secondary text-end text-break"
                      style="line-height: inherit !important; text-transform: none !important; white-space: break-spaces !important;">
                      {{ code || '' }}
                    </span>
                  </template>
                  <span v-else>-</span>
                </div>
              </li>
              <li>
                <hr class="horizontal dark" />
              </li>
              <li class="row">
                <div class="col-4 pe-0">
                  <p class="mb-0">Result:</p>
                </div>
                <div class="col-8 d-flex justify-content-end">
                  <template v-if="caseDetailsState.details?.result">
                    <span class="badge badge-sm text-wrap text-break text-capitalize text-start lh-base" :class="{
                      'bg-primary': String(caseDetailsState.details?.result ?? '').toLowerCase().includes('respondent'),
                      'bg-purple': String(caseDetailsState.details?.result ?? '').toLowerCase().includes('claimant'),
                      'bg-orange': String(caseDetailsState.details?.result ?? '').toLowerCase().includes('settled'),
                      'bg-dark': String(caseDetailsState.details?.result ?? '').toLowerCase().includes('not decided yet'),
                    }">
                      {{ caseDetailsState.details?.result ?? '' }}
                    </span>
                  </template>
                  <span v-else class="text-sm lh-lg ms-auto text-end text-bold title-color">-</span>
                </div>
              </li>
              <li>
                <hr class="horizontal dark" />
              </li>
              <li class="row">
                <div class="col-2 pe-0">
                  <p class="mb-0">More info:</p>
                </div>
                <div class="col-10 d-flex justify-content-end">
                  <span class="text-sm lh-lg text-end ms-auto overflow-hidden"
                    style="max-width: 100%; white-space: nowrap; text-overflow: ellipsis;">
                    <a v-if="caseDetailsState.details?.decisionUrl" :href="caseDetailsState.details?.decisionUrl || ''"
                      target="_blank">
                      {{ caseDetailsState.details?.decisionUrl }}
                    </a>
                    <span v-else>-</span>
                  </span>
                </div>
              </li>
              <li>
                <hr class="horizontal dark" />
              </li>
            </ul>
          </div>
        </div>
        <div v-if="!isEmpty(caseDetailsState.details?.judgment)" class="mt-4 mb-3 card mt-lg-0">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center gap-3">
              <h5 class="mb-0 text-primary">
                Judgements
              </h5>
              <img :src="require('@/assets/img/ai.png')" height="20px">
            </div>
          </div>
          <div class="pb-0 card-body">
            <ul class="mx-auto">
              <li v-for="judgment of caseDetailsState.details?.judgment" :key="judgment">
                {{ judgment }}
              </li>
            </ul>
          </div>
        </div>
        <div v-if="!isEmpty(caseDetailsState.details?.reasons)" class="mt-4 mb-3 card mt-lg-0">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center gap-3">
              <h5 class="mb-0 text-primary">
                Reasons
              </h5>
              <img :src="require('@/assets/img/ai.png')" height="20px">
            </div>
          </div>
          <div class="pb-0 card-body">
            <ul class="mx-auto">
              <li v-for="reason of caseDetailsState.details?.reasons" :key="reason">
                {{ reason }}
              </li>
            </ul>
          </div>
        </div>
        <div v-if="!isEmpty(caseDetailsState.details?.industries)" class="mt-4 mb-3 card mt-lg-0">
          <div class="card-header pb-0">
            <div class="row">
              <div class="col-12">
                <h5 class="mb-0 text-primary">
                  Industry Details
                </h5>
              </div>
            </div>
          </div>
          <div class="pb-0 card-body">
            <ul class="mx-auto list-unstyled">
              <li class="row">
                <div class="col-3 pe-0">
                  <p class="mb-0">Industry:</p>
                </div>
                <div class="col-9 d-flex justify-content-end flex-wrap gap-2 ms-auto">
                  <span v-for="ind of uniqBy(caseDetailsState.details?.industries || [], 'GroupShort')"
                    :key="`IND_${ind.GroupShort}`" class="badge badge-secondary badge-sm ms-auto text-break text-end"
                    style="line-height: inherit !important; white-space: break-spaces !important;">
                    {{ ind.GroupShort || '-' }}
                  </span>
                </div>
              </li>
              <li>
                <hr class="horizontal dark" />
              </li>
              <li class="row">
                <div class="col-3 pe-0">
                  <p class="mb-0">Sub-Industry:</p>
                </div>
                <div class="col-9 d-flex justify-content-end flex-wrap gap-2 ms-auto">
                  <span v-for="ind of uniqBy(caseDetailsState.details?.industries || [], 'CodeDesc')"
                    :key="`SUB_IND_${ind.CodeDesc}`" class="badge badge-secondary badge-sm ms-auto text-break text-end"
                    style="line-height: inherit !important; white-space: break-spaces !important;">
                    {{ ind.CodeDesc || '-' }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <template v-if="!isEmpty(caseDetailsState.details?.companies)">
          <div v-for="companyDetails of caseDetailsState.details?.companies" :key="companyDetails.id"
            class="mt-4 mb-3 card mt-lg-0">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-12">
                  <h5 class="mb-0 text-primary">
                    Company Details
                  </h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row bottom-gap-15">
                <div v-if="companyDetails.companyLogoUrl"
                  class="col-12 col-sm-3 d-flex justify-content-start d-sm-block">
                  <div class="p-2 d-flex justify-content-center h-100">
                    <img :src="companyDetails.companyLogoUrl" alt="Company Logo" class="w-100"
                      style="max-height: 120px; object-fit: contain;" />
                  </div>
                </div>
                <div v-if="companyDetails.incorporationDate" class="col-12 col-sm-3">
                  <div class="p-2 h-100">
                    <p class="mb-0 text-xs text-uppercase font-weight-bold">Incorporated</p>
                    <h6 class="mb-0 font-weight-bolder">
                      {{ getFormatedDate(companyDetails.incorporationDate, '', 'YYYY-MM-DD', true) }}
                    </h6>
                  </div>
                </div>
                <div v-if="companyDetails.address" class="col-12 col-sm-6">
                  <div class="p-2 h-100">
                    <p class="mb-0 text-xs text-uppercase font-weight-bold">Headquarter</p>
                    <h6 class="mb-0 font-weight-bolder">{{ capitalizeFirstLetter(companyDetails?.address || '-')
                      }}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="!isEmpty(caseDetailsState.details?.caseHistory)" class="col-12 col-lg-4">
        <timeline-list title="Case Timeline" title-class="h5 text-primary">
          <timeline-item v-for="history of caseDetailsState.details?.caseHistory" :key="history.date"
            :icon="{ component: 'fa fa-circle', color: 'primary' }" :title="capitalizeFirstLetter(history.title)"
            :date-time="getFormatedDate(history.date || '', '', 'YYYY-MM-DD HH:mm', true)" />
        </timeline-list>
      </div>
    </div>
    <div v-if="(caseDetailsState.details?.fileLinks || []).length > 0" class="mb-3 card">
      <div class="card-header pb-0">
        <div class="row">
          <div class="col-12">
            <h5 class="mb-0 text-primary">
              Case Files
            </h5>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div v-for="file of (caseDetailsState.details?.fileLinks || [])" :key="file"
            class="col-6 col-md-2 case_file_container mb-2 mb-md-0">
            <a :href="file" target="_blank" class="text-center">
              <img :src="require('@/assets/img/pdf-icon.svg')" alt="file icon" class="d-block mx-auto w-40 h-auto" />
              <p class="text-sm mb-0 mt-2 text-capitalize">{{ getFileName(file) }}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="!isEmpty(caseDetailsState.details?.cityData)" class="row mb-3">
      <div class="col-12">
        <div class="card">
          <div class="p-3 card-body">
            <p class="text-lg mb-2">
              <strong>Address:</strong> {{ (caseDetailsState.details?.cityData.Address || '').replaceAll("\n", ", ") }}
            </p>
            <div id="case_details_map" class="leaflet" style="height: 400px;"></div>
          </div>
        </div>
      </div>
    </div> -->
    <div v-if="(caseDetailsState?.details?.applicableLawsAndRegulations ?? []).length > 0" class="card mb-3">
      <div class="card-header pb-0">
        <div class="d-flex align-items-center gap-3">
          <h5 class="mb-0 text-primary">
            Relevant Legislation
          </h5>
          <img :src="require('@/assets/img/ai.png')" height="20px">
        </div>
      </div>
      <div class="card-body p-3 pt-0 mt-0">
        <div id="CaseDetailsLawNRegulations" class="px-2 pt-3 accordion">
          <accordion-item v-for="(law, i) of caseDetailsState?.details?.applicableLawsAndRegulations ?? []"
            :key="`${law?.lawName}`" :accordian-button-class="`border-bottom rounded-0 px-0${i == 0 ? ' pt-0' : ''}`"
            accordian-body-class="px-0" parent-id="CaseDetailsLawNRegulations"
            :accordion-id="`heading_${(law?.lawName || '').replace(/[^a-zA-Z0-9]/g, '').replace(/ /g, '_')}`"
            :collapse-id="`collapse_${(law?.lawName || '').replace(/[^a-zA-Z0-9]/g, '').replace(/ /g, '_')}`"
            :active="false">
            <template #question>
              <div class="text-lg title-color">{{ law?.lawName || '-' }}</div>
            </template>
            <template #answer>
              <div v-html="law?.lawSummary || '-'"></div>
            </template>
          </accordion-item>
        </div>
      </div>
    </div>
    <div v-if="(caseDetailsState.details?.referredCases || []).length > 0" class="card mb-3">
      <div class="card-header pb-0">
        <div class="d-flex align-items-center gap-3">
          <h5 class="mb-0 text-primary">
            Case Law
          </h5>
          <img :src="require('@/assets/img/ai.png')" height="20px">
        </div>
      </div>
      <div class="card-body p-3">
        <ul class="mb-2">
          <li v-for="(caseTitle, i) of caseDetailsState.details?.referredCases || []" :key="`${i}_${caseTitle}`">
            {{ caseTitle || '' }}
          </li>
        </ul>
      </div>
      <!-- <div class="card-body p-3 pt-0 mt-0 overflow-auto" style="max-height: 400px;">
        <table class="table table-sm table-striped table-hover" style="table-layout: fixed; word-wrap: break-word;">
          <thead>
            <th scope="col">Case Name</th>
            <th class="text-center" scope="col">Date</th>
            <th class="text-center" scope="col">Claimant</th>
            <th class="text-center" scope="col">Case Type</th>
            <th scope="col">Respondent</th>
            <th scope="col">Point of Law</th>
            <th scope="col">Decision Files</th>
          </thead>
          <tbody>
            <tr
              v-for="(cityCase, i) of caseDetailsState.cityCases.slice(caseDetailsState.pageNo == 1 ? 0 : ((caseDetailsState.pageNo - 1) * caseDetailsState.dataSize), caseDetailsState.pageNo * caseDetailsState.dataSize)"
              :key="`${i}.${cityCase.id} `">
              <td class="value text-start align-baseline">
                <span v-if="isNewCase(cityCase)" class="badge badge-sm bg-primary text-white text-break me-1 mt-1">
                  New
                </span>
                <span class="case_title text-capitalize" @click="activeCaseDetailsTab(String(cityCase.id), cityCase?.caseName)">
                  {{ cityCase.caseName || '-' }}</span>
              </td>
              <td class="value text-center text-capitalize align-baseline">
                {{ !cityCase.caseDate ? '-' : `${getFormatedDate(cityCase.caseDate)}` }}
              </td>
              <td class="value text-center text-capitalize align-baseline">
                {{ capitalizeFirstLetter(cityCase.claimant || '-') }}
              </td>
              <td class="value text-center text-capitalize align-baseline">{{ cityCase.caseType }}</td>
              <td class="value text-capitalize align-baseline">
                {{ capitalizeFirstLetter(cityCase.respondent || '-') }}
              </td>
              <td class="value align-baseline">
                <div v-if="cityCase.category" class="d-flex align-items-center flex-wrap gap-2">
                  <span v-for="code of cityCase.category" :key="code"
                    class="badge badge-sm bg-primary text-wrap text-break text-start lh-base"
                    style="text-transform: none !important;">
                    {{ code }}
                  </span>
                </div>
                <span v-else class="text-center">-</span>
              </td>
              <td class="text-center align-baseline">
                <div class="d-flex align-items-center justify-content-center flex-wrap gap-1">
                  <template v-if="cityCase.fileLinks && cityCase.fileLinks.length > 0">
                    <a v-for="file of cityCase.fileLinks" :key="file" :href="file" target="_blank">
                      <img :src="require('@/assets/img/pdf-icon.svg')" alt="file icon" class="me-2"
                        style="height: 25px;" />
                    </a>
                  </template>
                  <span v-else class="text-center">-</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="caseDetailsState.cityCases.length == 0">
          <h4 class="text-center text-lg">No cases found</h4>
        </div>
      </div>
      <div v-if="caseDetailsState.cityCases.length > caseDetailsState.dataSize" class="card-footer px-0">
        <div class="pagination-container">
          <vue-awesome-paginate v-model="caseDetailsState.pageNo" :max-pages-shown="3" :items-per-page="caseDetailsState.dataSize"
            :total-items="caseDetailsState.cityCases.length" @click="onPageNoChange">
            <template #prev-button>
              <svg xmlns="http://www.w3.org/2000/svg" fill="black" width="10" height="10" viewBox="0 0 24 24">
                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
              </svg>
            </template>
            <template #next-button>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="black" width="10" height="10" viewBox="0 0 24 24">
                  <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                </svg>
              </span>
            </template>
          </vue-awesome-paginate>
        </div>
      </div> -->
    </div>
    <div v-if="caseDetailsState.similarCasesData.length > 0" class="card">
      <div class="card-header pb-0">
        <h5 class="mb-0 text-primary">
          Similar Cases
        </h5>
      </div>
      <div class="card-body p-0 d-flex">
        <div class="similar-case-card-parent-container p-3">
          <div v-for="(simCas, i) of caseDetailsState.similarCasesData" :key="`${i}_${simCas.decisionID}`"
            class="card similar-case-card" @click="activeCaseDetailsTab(String(simCas.decisionID), simCas?.caseName)">
            <div class="text-sm lh-base similar-case-title">
              {{ simCas.caseName || '-' }}
            </div>
            <div class="text-sm">Date: {{ getFormatedDate(simCas?.caseDate || "", '', 'YYYY-MM-DD', true) }}</div>
            <div class="progress progress-xs mb-0 mt-2" style="height: 20px;">
              <div :class="`progress-bar bg-male`" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                :aria-valuenow="Number(simCas?.similarityScore || 0)"
                :style="{ width: `${Number(simCas?.similarityScore || 0)}` + '%' }">
                <span class="text-xxs lh-xxs font-weight-bold">
                  {{ Number(Number(Number(simCas?.similarityScore || 0)).toFixed(2)) }}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// import moment from "moment";
import { useStore } from "vuex";
import uniqBy from "lodash-es/uniqBy";
import isEmpty from "lodash-es/isEmpty";
// import orderBy from "lodash-es/orderBy";
import { watch, computed, reactive } from "vue";

import AccordionItem from '@/views/pages/components/AccordionItem2.vue';
import TimelineList from '@/examples/Cards/TimelineList.vue';
import TimelineItem from '@/examples/Cards/TimelineItem.vue';
import ArgonLoader from '@/components/ArgonLoader.vue';

import {
  // isNewCase,
  handleError,
  getFormatedDate,
  capitalizeFirstLetter,
} from '@/utils/utils.js';
import elasticSearchApiInstance, { getSimilarCaseFilter } from "@/utils/elasticSearch";
import eventBus from '@/utils/eventBus';

const store = useStore();

const caseDetailsState = reactive({
  details: {},
  fetching: false,
  similarCasesData: [],
  pageNo: 1,
  dataSize: 50,
});

// const caseDetailsMap = ref(null);
const selectedCase = computed(() => store.getters['dashboard/caseDetailFilters'].selectedCase || '');

const getCaseDetails = async (id) => {
  if (id) {
    try {
      caseDetailsState.fetching = true;
      const filterData = {
        query: { bool: { filter: { term: { decisionID: id } } } }
      };
      const response = await elasticSearchApiInstance.post('/_search', filterData);
      const { hits = [] } = response?.data?.hits ?? {};
      const cDetail = hits.find(c => c._source.decisionID == id) || {};
      const similarCasesResponse = await elasticSearchApiInstance.post('/_search', getSimilarCaseFilter(cDetail?._index, cDetail?._id));
      const similarCases = (similarCasesResponse.data?.hits?.hits ?? []);
      caseDetailsState.details = cDetail?._source ?? {};
      caseDetailsState.similarCasesData = similarCases.map(c => ({
        ...(c?._source ?? {}),
        similarityScore: c?._score ?? 0,
      }));

      // setTimeout(() => {
      //   renderMap();
      // }, 2000);

      setTimeout(() => {
        caseDetailsState.fetching = false;
        setTimeout(() => {
          document.querySelector('main').scrollTo({ top: 0, left: 0, behavior: 'auto' });
        });
      }, 1000);
    } catch (error) {
      caseDetailsState.fetching = false;
      const errorMessage = handleError(error);
      if (errorMessage) {
        this.$swal({
          title: 'Error!',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'Okay',
          customClass: {
            confirmButton: "btn bg-success",
          },
          buttonsStyling: false,
        });
      }
    }
  } else {
    caseDetailsState.fetching = false;
  }
};

// const getCaseJudgement = () => {
//   if (!isEmpty(caseDetailsState.details?.result)) {
//     return (caseDetailsState.details?.result || []).map(r => {
//       if (r && r.toLowerCase() == 'Respondent won') {
//         return `${capitalizeFirstLetter((caseDetailsState?.details?.respondent || []).join(', '))} won the case.`;
//       } else if (r && r.toLowerCase() == 'Claimant won') {
//         return `${capitalizeFirstLetter((caseDetailsState?.details?.claimant || []).join(', '))} won the case.`;
//       } else if (r && r.toLowerCase() == 'Settled') {
//         return 'The case was settled.';
//       } else {
//         return 'Not decided yet.';
//       }
//     }).join("\n")
//   } else {
//     return '-';
//   }
// };

const getFileName = (fileUrl) => {
  if (!fileUrl) return '';
  let name = fileUrl.split('/').pop().replaceAll('_', ' ');
  if (name.includes('.')) {
    name = name.split('.');
    name.pop();
    name = name.join('.');
  }
  return capitalizeFirstLetter(name);
};

// const activeCompanyTab = () => {
//   const get = document.getElementsByClassName("company-tab")[0];
//   if (get) {
//     setTimeout(() => {
//       get.click();
//       get.classList.add('active');
//     }, 200);
//   }
// };
// 
// const onRespondantClick = (respondent) => {
//   if (isEmpty(respondent)) return null;
//   store.commit('dashboard/updateCompanyFilters', { selectedCompany: respondent.id || "" });
//   activeCompanyTab();
// };

// const onPageNoChange = (page) => {
//   caseDetailsState.pageNo = page;
// };

// const renderMap = () => {
//   if (!isEmpty(caseDetailsState.details?.cityData)) {
//     if (caseDetailsMap.value) {
//       caseDetailsMap.value.remove();
//       caseDetailsMap.value = null;
//     }
//     setTimeout(() => {
//       const latLong = [caseDetailsState.details?.cityData?.Lat, caseDetailsState.details?.cityData?.Lon];
//       caseDetailsMap.value = L.map('case_details_map', {
//         zoom: 15,
//         latlng: latLong,
//       }).setView(latLong, 15);

//       new L.tileLayer(
//         'https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png',
//         {
//           attribution: '',
//           subdomains: 'abcd',
//         }
//       ).addTo(caseDetailsMap.value);
//       const icon = L.icon({
//         iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//         iconUrl: require('leaflet/dist/images/marker-icon.png'),
//         shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
//         iconSize: [25, 41],
//         iconAnchor: [12, 41],
//         popupAnchor: [1, -34],
//         tooltipAnchor: [16, -28],
//         shadowSize: [41, 41],
//       });
//       L.marker(latLong, { icon }).addTo(caseDetailsMap.value);
//     }, 1000);
//   }
// };

const activeCaseDetailsTab = (caseId, caseName) => {
  store.commit('dashboard/updateCaseDetailFilters', { selectedCase: caseId });
  eventBus.emit('on-case-details-change', { id: caseId, query: caseName });
};
watch(() => selectedCase.value, (newId) => {
  if (newId) {
    caseDetailsState.fetching = true;
    getCaseDetails(newId);
  }
});
getCaseDetails(selectedCase.value);

</script>
<style scoped>
.empty-container {
  align-items: flex-start !important;
}

th {
  font-size: 0.9rem;
  text-wrap: wrap;
  text-align: center;
  padding: 0.75rem 0.25rem !important;
}

td.value {
  font-size: 0.8rem;
  line-height: 1.2rem;
  vertical-align: middle;
  text-align: center;
  text-wrap: wrap;
}

.case_file_container {
  transition: all;
  transition-duration: 300ms;
}

.case_file_container:hover,
.case_file_container:active,
.case_file_container:focus-visible,
.case_file_container:focus-within,
.case_file_container:target,
.case_file_container:visited,
.case_file_container:focus {
  scale: 1.1;
}

.case_file_container:hover>a>p,
.case_file_container:active>a>p,
.case_file_container:focus-visible>a>p,
.case_file_container:focus-within>a>p,
.case_file_container:target>a>p,
.case_file_container:visited>a>p,
.case_file_container:focus>a>p {
  color: rgb(var(--bs-primary-rgb)) !important;
}

.bg-orange {
  background-color: #FFBE0B;
}

.bg-purple {
  background-color: #702962;
}

@media only screen and (max-width : 768px) {
  table {
    table-layout: auto !important;
  }

  .th {
    padding: 0.75rem 1.25rem !important;
  }
}
</style>