<template>
  <div style="min-height: 85vh;">
    <div class="card">
      <div class="card-body py-4">
        <div class="row">
          <div class="col-12">
            <h5 class="title-color">Please enter data to predict case outcome</h5>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-4 col-sm-12">
            <label class="form-label title-color">Industry</label>
            <select id="prediction_industry" class="form-control choices__inner border-0" name="prediction_industry"
              @change="onIndustryChange($event.target.value)" />
          </div>
          <div class="col-md-4 col-sm-12">
            <label class="form-label title-color">Sub-Industries</label>
            <select id="prediction_sub_industry" class="form-control choices__inner border-0"
              name="prediction_sub_industry" @change="onSubIndustryChange($event.target.value)" />
          </div>
          <div class="col-md-4 col-sm-12">
            <label class="form-label title-color">Point of Law</label>
            <select id="prediction_point_of_law" class="form-control choices__inner border-0"
              name="prediction_point_of_law" @change="onPointOfLawChange($event.target.value)" />
          </div>
        </div>
        <!-- <argon-button color="primary" class="mt-4" @click.prevent="predictCase">Predict case</argon-button> -->
        <p class="mt-4 mb-0">
          According to our statistics, we predict that the claimant would win with a probability of <span
            class="border border-success p-1 text-success">{{ percentage }}%</span>
          and a tribunal award of <span class="border border-primary p-1">{{ amount }}</span>
        </p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="card overflow-auto mb-0">
          <div id="prediction_cases_table" class="card-body p-3 pt-0 mt-3 overflow-auto position-relative"
            style="max-height: 400px;">
            <table class="table table-sm table-striped table-hover" style="table-layout: fixed; word-wrap: break-word;">
              <thead>
                <th scope="col">Case Name</th>
                <th scope="col">Date</th>
                <th scope="col">Claimant</th>
                <th scope="col">Case Type</th>
                <th scope="col">Respondent</th>
                <th scope="col">Point of Law</th>
                <th scope="col">Case Outcome</th>
                <th scope="col">Tribunal Award</th>
              </thead>
              <tbody>
                <tr v-for="(cases, i) of caseTable" :key="`${i}.${cases?.decisionID} `">
                  <td class="value text-start align-baseline">
                    <span v-if="isNewCase(cases)" class="badge badge-sm bg-primary text-white me-1 mt-1">
                      New
                    </span>
                    <span class="text-capitalize" :class="[cases?.decisionID ? 'case_title' : '']"
                      @click="activeCaseDetailsTab(String(cases?.decisionID), cases?.caseName ?? '')">{{ cases?.caseName
                        || '-'
                      }}</span>
                  </td>
                  <td class="value text-center text-capitalize align-baseline">
                    {{ !cases?.caseDate ? '-' : `${getFormatedDate(cases?.caseDate, '', '', true)}` }}
                  </td>
                  <td class="value text-center text-capitalize align-baseline">
                    {{ cases?.claimant ? capitalizeFirstLetter(cases?.claimant) : '-' }}
                  </td>
                  <td class="value text-center text-capitalize align-baseline">{{ cases?.caseType || '-' }}</td>
                  <td class="value text-capitalize align-baseline">
                    {{ cases?.respondent && cases?.respondent.length > 0 ? cases?.respondent.join(", ") : '-' }}
                  </td>
                  <td class="value align-baseline">
                    <div v-if="cases?.category && cases?.category.length"
                      class="d-flex align-items-center flex-wrap gap-2">
                      <span v-for="code of cases?.category" :key="code"
                        class="badge badge-sm bg-secondary text-wrap text-break text-start lh-base cursor-pointer"
                        style="text-transform: none !important;" @click.prevent="onPointOfLawPress(code)">
                        {{ code }}
                      </span>
                    </div>
                    <span v-else class="text-center">-</span>
                  </td>
                  <td class="value text-capitalize align-baseline">
                    <div v-if="cases?.result" class="d-flex align-items-center flex-wrap gap-2">
                      <span class="badge badge-sm text-wrap text-break text-capitalize text-start lh-base" :class="{
                        'bg-primary': String(cases?.result ?? '').toLowerCase().includes('respondent'),
                        'bg-purple': String(cases?.result ?? '').toLowerCase().includes('claimant'),
                        'bg-orange': String(cases?.result ?? '').toLowerCase().includes('settled'),
                        'bg-dark': String(cases?.result ?? '').toLowerCase().includes('not decided yet'),
                      }">
                        {{ cases?.result ?? '' }}
                      </span>
                    </div>
                    <span v-else class="text-center">-</span>
                  </td>
                  <td class="value text-capitalize align-baseline">
                    {{ getTribunalAward(cases?.orderedPayment || 0) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="caseTable.length == 0">
              <h4 class="text-center text-lg">No cases found</h4>
            </div>
          </div>
          <div v-if="totalCases > 0" class="card-footer px-0">
            <div class="pagination-container">
              <vue-awesome-paginate v-model="pageNo" :max-pages-shown="3" :items-per-page="dataSize"
                :total-items="totalCases" @click="onPageNoChange">
                <template #prev-button>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="black" width="10" height="10" viewBox="0 0 24 24">
                    <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                  </svg>
                </template>
                <template #next-button>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="black" width="10" height="10" viewBox="0 0 24 24">
                      <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                    </svg>
                  </span>
                </template>
              </vue-awesome-paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="fetching || fetchingCases" class="empty-view">
      <argon-loader />
    </div>
  </div>
</template>

<script>
import Choices from 'choices.js';
import { mapMutations } from 'vuex';
import uniqBy from "lodash-es/uniqBy";
import orderBy from "lodash-es/orderBy";

import ArgonLoader from '@/components/ArgonLoader.vue';

import {
  isNewCase,
  nFormatter,
  handleError,
  clearEmpties,
  // getNumberOnly,
  generateRandom,
  getFormatedDate,
  capitalizeFirstLetter,
} from "@/utils/utils.js";
import elasticSearchApiInstance, {
  predictionsTabFilterAggs,
} from "@/utils/elasticSearch";
import eventBus from "@/utils/eventBus";

export default {
  name: "PredictionComponent",
  components: { ArgonLoader },
  data() {
    return {
      amount: 0.0,
      percentage: 0,
      selectedPointOfLaw: '',
      selectedIndustry: '',
      selectedSubIndustry: '',
      industryOptions: [],
      subIndustryOptions: [],
      pointOfLaws: [],
      caseTable: [],
      pageNo: 1,
      dataSize: 50,
      totalCases: 0,
      fetching: false,
      fetchingCases: false,
    }
  },
  unmounted() {
    if (this.industryIndustriesRef) {
      this.industryIndustriesRef.destroy();
      this.industryIndustriesRef = null;
    }
    if (this.industrySubIndustriesRef) {
      this.industrySubIndustriesRef.destroy();
      this.industrySubIndustriesRef = null;
    }
    if (this.pointOfLawRef) {
      this.pointOfLawRef.destroy();
      this.pointOfLawRef = null;
    }
  },
  created() {
    this.fetching = true;
    this.getFilters();
  },
  methods: {
    isNewCase,
    nFormatter,
    generateRandom,
    getFormatedDate,
    capitalizeFirstLetter,
    ...mapMutations("dashboard", ["updateCaseDetailFilters"]),
    async dataMounted() {
      if (document.getElementById("prediction_industry")) {
        if (this.industryIndustriesRef) {
          this.industryIndustriesRef.clearChoices();
          this.industryIndustriesRef.destroy();
          this.industryIndustriesRef = null;
        }
        var industry = document.getElementById("prediction_industry");
        this.industryIndustriesRef = new Choices(industry, {
          choices: this.industryOptions,
          shouldSort: false,
          itemSelectText: '',
          searchEnabled: true,
          searchChoices: true,
          searchResultLimit: -1,
          searchFloor: 0,
          searchFields: ['label'],
          duplicateItemsAllowed: false,
          fuseOptions: { threshold: 0 },
          searchPlaceholderValue: "Search Industries",
          classNames: {
            containerOuter: ["choices", "custom_menu_list"]
          }
        });
        this.industryIndustriesRef.setChoiceByValue(this.selectedIndustry);
      }
      if (document.getElementById("prediction_sub_industry")) {
        if (this.industrySubIndustriesRef) {
          this.industrySubIndustriesRef.clearChoices();
          this.industrySubIndustriesRef.destroy();
          this.industrySubIndustriesRef = null;
        }
        var subIndustry = document.getElementById("prediction_sub_industry");
        this.industrySubIndustriesRef = new Choices(subIndustry, {
          choices: this.subIndustryOptions,
          shouldSort: false,
          itemSelectText: '',
          searchEnabled: true,
          searchChoices: true,
          searchResultLimit: -1,
          searchFloor: 0,
          searchFields: ['label'],
          duplicateItemsAllowed: false,
          fuseOptions: { threshold: 0 },
          searchPlaceholderValue: "Search Sub-Industries",
          classNames: {
            containerOuter: ["choices", "custom_menu_list"]
          }
        });
        this.industrySubIndustriesRef.setChoiceByValue(this.selectedSubIndustry);
      }
      if (document.getElementById("prediction_point_of_law")) {
        if (this.pointOfLawRef) {
          this.pointOfLawRef.clearChoices();
          this.pointOfLawRef.destroy();
          this.pointOfLawRef = null;
        }
        var caseType = document.getElementById("prediction_point_of_law");
        this.pointOfLawRef = new Choices(caseType, {
          choices: this.pointOfLaws,
          shouldSort: false,
          itemSelectText: '',
          searchEnabled: true,
          searchChoices: true,
          searchResultLimit: -1,
          searchFloor: 0,
          searchFields: ['label'],
          duplicateItemsAllowed: false,
          fuseOptions: { threshold: 0 },
          searchPlaceholderValue: "Search Point of Law",
          classNames: {
            containerOuter: ["choices", "custom_menu_list"]
          }
        });
        this.pointOfLawRef.setChoiceByValue(this.selectedPointOfLaw);
      }
      await this.getCases();
      await this.predictCase();
      this.fetching = false;
    },
    getGroupWiseData(casesData) {
      let indGrp = {}, subIndGrp = {};
      casesData.forEach(cs => {
        (cs.industryData || []).forEach(ind => {
          if (ind?.GroupShort) {
            // eslint-disable-next-line no-prototype-builtins
            if (indGrp[(ind.GroupShort)]) {
              indGrp[ind.GroupShort].push(cs);
            } else {
              indGrp[ind.GroupShort] = [cs];
            }
          }
          if (ind?.CodeDesc) {
            // eslint-disable-next-line no-prototype-builtins
            if (subIndGrp[(ind.CodeDesc)]) {
              subIndGrp[ind.CodeDesc].push(cs);
            } else {
              subIndGrp[ind.CodeDesc] = [cs];
            }
          }
        })
      });
      return { indGrp, subIndGrp };
    },
    onIndustryChange(value) {
      this.selectedIndustry = value;
      this.selectedSubIndustry = '';
      this.selectedPointOfLaw = '';
      setTimeout(async () => {
        this.fetchingCases = true;
        await this.getFilters();
        await this.getCases();
        await this.predictCase();
        this.fetchingCases = false;
      }, 400);
    },
    onSubIndustryChange(value) {
      this.selectedSubIndustry = value;
      setTimeout(async () => {
        this.fetchingCases = true;
        await this.getFilters();
        await this.getCases();
        await this.predictCase();
        this.fetchingCases = false;
      }, 400);
    },
    onPointOfLawChange(value) {
      this.selectedPointOfLaw = value;
      setTimeout(async () => {
        this.fetchingCases = true;
        await this.getCases();
        await this.predictCase();
        this.fetchingCases = false;
      }, 400);
    },
    onPointOfLawPress(value) {
      this.pageNo = 1;
      this.onPointOfLawChange(value);
      if (this.pointOfLawRef) {
        const currentPointOfLaw = this.pointOfLawRef.getValue(true);
        if (currentPointOfLaw !== value) {
          this.pointOfLawRef.setChoiceByValue(value);
        }
      }
    },
    getTribunalAward(orderedPayment) {
      // const casesAmounts = orderedPayment.map(n => Number(getNumberOnly(n)));
      // let amount = 0;
      // if (casesAmounts.length > 1) {
      //   amount = Number(Number(casesAmounts.reduce((a, b) => a + (!isNaN(b) ? b : 0), 0)).toFixed(2));
      // } else if (casesAmounts.length == 1) {
      //   amount = casesAmounts[0];
      // }
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(isNaN(orderedPayment) ? 0 : orderedPayment);
    },
    onPageNoChange(page) {
      this.pageNo = page;
      setTimeout(async () => {
        this.fetchingCases = true;
        await this.getCases();
        this.fetchingCases = false;
      }, 500);
    },
    activeCaseDetailsTab(caseId, caseName) {
      if (!caseId) return null;
      this.updateCaseDetailFilters({ selectedCase: String(caseId) });
      eventBus.emit('on-case-details-change', { id: caseId, query: caseName });
      setTimeout(() => {
        const get = document.getElementsByClassName("case-details-tab")[0];
        if (get) {
          setTimeout(() => {
            get.click();
            get.classList.add('active');
          }, 200);
        }
      }, 500);
    },
    async getFilters() {
      try {
        const filterData = {
          size: 0,
          query: { bool: {} },
          aggs: predictionsTabFilterAggs,
        };
        const queryFilters = [];
        if (this.selectedIndustry) {
          queryFilters.push({ term: { 'industries.GroupShort.keyword': this.selectedIndustry } });
        }
        if (this.selectedSubIndustry) {
          queryFilters.push({ term: { 'industries.CodeDesc.keyword': this.selectedSubIndustry } });
        }
        if (queryFilters.length) {
          filterData.query.bool = { filter: queryFilters };
        }

        const response = await elasticSearchApiInstance.post('/_search', clearEmpties(filterData));
        const { industries, subIndustries, pointOfLaws } = response?.data?.aggregations || {};

        const industryOptions = orderBy(uniqBy((industries?.buckets ?? []).map(c => ({ label: c?.key ?? '', value: c?.key ?? '' })), 'value'), ['label'], ['asc']);
        industryOptions.unshift({ label: "Select Industry", value: "" });
        const subIndustryOptions = orderBy(uniqBy((subIndustries?.buckets ?? []).map(c => ({ label: c?.key ?? '', value: c?.key ?? '' })), 'value'), ['label'], ['asc']);
        subIndustryOptions.unshift({ label: "Select Sub-Industries", value: "" });
        const pointOfLawsOptions = orderBy(uniqBy((pointOfLaws?.buckets ?? []).map(c => ({ label: c?.key ?? '', value: c?.key ?? '' })), 'value'), ['label'], ['asc']);
        pointOfLawsOptions.unshift({ label: "All", value: "" });

        this.industryOptions = industryOptions;
        this.subIndustryOptions = subIndustryOptions;
        this.pointOfLaws = pointOfLawsOptions;

        this.dataMounted();
      } catch (error) {
        const errorMessage = handleError(error);
        if (errorMessage) {
          this.$swal({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: "btn bg-success",
            },
            buttonsStyling: false,
          });
        }
        this.fetching = false;
      }
    },
    async getCases() {
      try {
        const from = this.pageNo - 1 != 0 ? (this.dataSize) * (this.pageNo - 1) : this.pageNo - 1;
        const filterData = {
          from: from,
          size: this.dataSize,
          query: { bool: {} },
        };
        const queryFilters = [];
        if (this.selectedIndustry) {
          queryFilters.push({ term: { 'industries.GroupShort.keyword': this.selectedIndustry } });
        }
        if (this.selectedSubIndustry) {
          queryFilters.push({ term: { 'industries.CodeDesc.keyword': this.selectedSubIndustry } });
        }
        if (this.selectedPointOfLaw) {
          queryFilters.push({ term: { 'category.keyword': this.selectedPointOfLaw } });
        }
        if (queryFilters.length) {
          filterData.query.bool = { filter: queryFilters };
        }
        const countFilterData = { query: { ...filterData.query } };
        const [response, countResponse] = await Promise.all([
          elasticSearchApiInstance.post('/_search', clearEmpties(filterData)),
          elasticSearchApiInstance.post('/_count', clearEmpties(countFilterData)),
        ]);
        const { hits: allCases = [] } = response?.data?.hits ?? {};
        this.totalCases = countResponse?.data?.count ?? 0;
        this.caseTable = allCases?.map(c => c._source);
      } catch (error) {
        const errorMessage = handleError(error);
        if (errorMessage) {
          this.$swal({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: "btn bg-success",
            },
            buttonsStyling: false,
          });
        }
        this.fetching = false;
      }
    },
    async predictCase() {
      try {
        const filter = [];
        const searchQueryFields = {
          size: 0,
          aggs: {
            amount: {
              sum: { field: "orderedPayment" }
            }
          },
          query: { bool: {} },
        }
        if (this.selectedIndustry) {
          filter.push({ term: { 'industries.GroupShort.keyword': this.selectedIndustry } });
        }
        if (this.selectedSubIndustry) {
          filter.push({ term: { 'industries.CodeDesc.keyword': this.selectedSubIndustry } });
        }
        if (this.selectedPointOfLaw) {
          filter.push({ term: { 'category.keyword': this.selectedPointOfLaw } });
        }
        if (filter.length > 0) {
          searchQueryFields.query.bool = { filter };
        }
        const [countResponse, amountSumResponse] = await Promise.all([
          elasticSearchApiInstance.post('/_count', {
            query: {
              bool: {
                filter: [...filter, { term: { "result.keyword": "Claimant won" } }]
              }
            }
          }),
          elasticSearchApiInstance.post('/_search', searchQueryFields),
        ]);
        const { amount: totalAmount } = amountSumResponse?.data?.aggregations ?? {};

        const totalClaimantWonCases = countResponse?.data?.count ?? 0;
        const totalFilteredCases = this.totalCases;
        if (totalFilteredCases > 0) {
          let amount = totalAmount?.value ?? 0;
          if (totalClaimantWonCases > 0) {
            amount /= totalClaimantWonCases;
          }
          amount = Number(Number(amount).toFixed(2));
          this.amount = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(isNaN(amount) ? 0 : amount);

          if (totalClaimantWonCases > 0) {
            this.percentage = Number(Number((totalClaimantWonCases * 100) / totalFilteredCases).toFixed(2));
          } else {
            this.percentage = 0.00;
          }
        } else {
          this.percentage = 0.00;
          this.amount = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(0.00);
        }
      } catch (error) {
        const errorMessage = handleError(error);
        if (errorMessage) {
          this.$swal({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: "btn bg-success",
            },
            buttonsStyling: false,
          });
        }
        this.fetching = false;
      }
    },
  },
}
</script>

<style scoped>
select {
  border-radius: 0px;
  color: #fff;
  font-size: 13px;
  padding: 0.3rem 0.5rem;
}

.choices[data-type*=select-one]::after {
  color: #fff !important;
}

.custom_menu_list .choices__list--dropdown .choices__list {
  max-height: 180px;
}

.bg-orange {
  background-color: #FFBE0B;
}

.bg-purple {
  background-color: #702962;
}

th {
  font-size: 0.9rem;
  text-wrap: wrap;
  text-align: center;
  padding: 0.75rem 0.25rem !important;
}

td.value {
  font-size: 0.8rem;
  line-height: 1.2rem;
  vertical-align: middle;
  text-align: center;
  text-wrap: wrap;
}

@media only screen and (max-width : 768px) {
  table {
    table-layout: auto !important;
  }

  .th {
    padding: 0.75rem 1.25rem !important;
  }
}
</style>